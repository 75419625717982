const SansthaAcademicJSON = [
    {
        MenuType: "normal",
        PathName: "/Home/dashboard",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "megamenu",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        MegaMenuDropDown: [
            {
                HeaddingName: "Masters",
                MegaMenuSubItems: [
                    {
                        id: 101,
                        SubPath: "/Home/m009/0",
                        SubName: "Manage Employee"
                    },
                    {
                        id: 102,
                        SubPath: "/Home/m001/2",
                        SubName: "Manage Designation"
                    },
                    {
                        id: 103,
                        SubPath: "/Home/m001/3",
                        SubName: "Manage Occupation"
                    },
                    {
                        id: 104,
                        SubPath: "/Home/m001/4",
                        SubName: "Academic Qualification"
                    },
                    {
                        id: 105,
                        SubPath: "/Home/m001/5",
                        SubName: "Professional Qualification"
                    },
                    {
                        id: 106,
                        SubPath: "/Home/m001/6",
                        SubName: "Manage Mother Tongue"
                    },
                    {
                        id: 107,
                        SubPath: "/Home/m002/7",
                        SubName: "Caste Category"
                    },
                    {
                        id: 108,
                        SubPath: "/Home/m001/8",
                        SubName: "Manage Caste"
                    },
                    {
                        id: 109,
                        SubPath: "/Home/m003/9",
                        SubName: "Manage Subcast"
                    },
                    {
                        id: 110,
                        SubPath: "/Home/m001/10",
                        SubName: "Nationality Master"
                    },
                    {
                        id: 111,
                        SubPath: "/Home/m002/11",
                        SubName: "Manage Domicile"
                    },
                    {
                        id: 112,
                        SubPath: "/Home/m002/12",
                        SubName: "Manage Religion"
                    },
                ]

            },
            {
                HeaddingName: " ",
                MegaMenuSubItems: [
                    {
                        id: 113,
                        SubPath: "/Home/m004/13",
                        SubName: "Manage student grade"
                    },
                    {
                        id: 114,
                        SubPath: "/Home/m001/14",
                        SubName: "Manage Concession"
                    },
                    {
                        id: 115,
                        SubPath: "/Home/m001/15",
                        SubName: "Manage Disability"
                    },
                    {
                        id: 116,
                        SubPath: "/Home/m002/16",
                        SubName: "Manage Subject"
                    },
                    {
                        id: 129,
                        SubPath: "/Home/m129/0",
                        SubName: "Institute Master"
                    },
                    {
                        id: 130,
                        SubPath: "/Home/m130/",
                        SubName: "Fee Master"
                    },
                    {
                        id: 117,
                        SubPath: "/Home/m006/17",
                        SubName: "Manage Division"
                    },
                    {
                        id: 118,
                        SubPath: "/Home/m001/18",
                        SubName: "Manage Progress Conduct"
                    },
                    {
                        id: 119,
                        SubPath: "/Home/m005/19",
                        SubName: "Manage Class"
                    },
                    {
                        id: 120,
                        SubPath: "/Home/m002/20",
                        SubName: "Manage Language"
                    },
                    {
                        id: 121,
                        SubPath: "/Home/m001/21",
                        SubName: "Manage Leaving Remark"
                    },
                    {
                        id: 122,
                        SubPath: "/Home/m002/22",
                        SubName: "Manage CWSN"
                    },
                    {
                        id: 131,
                        SubPath: "/Home/m001/123",
                        SubName: "Award Master"
                    },

                ]

            },
            {
                HeaddingName: " ",
                MegaMenuSubItems: [

                    {
                        id: 127,
                        SubPath: "/Home/m002/127",
                        SubName: "Minority Master"
                    },
                    {
                        id: 123,
                        SubPath: "/Home/m007/23",
                        SubName: "Exam Master"
                    },
                    {
                        id: 124,
                        SubPath: "/Home/m008/23",
                        SubName: "Sub Exam Master"
                    },
                    {
                        id: 128,
                        SubPath: "/Home/m001/128",
                        SubName: "Manage Stream"
                    },
                    {
                        id: 132,
                        SubPath: "/Home/m001/132",
                        SubName: "City Master"
                    },
                    {
                        id: 133,
                        SubPath: "/Home/m001/133",
                        SubName: "Document Master"
                    },
                    {
                        id: 134,
                        SubPath: "/Home/m01/134",
                        SubName: "Old School Master"
                    },
                    {
                        id: 135,
                        SubPath: "/Home/m01/135",
                        SubName: "Bank Master"
                    },
                    {
                        id: 136,
                        SubPath: "/Home/m01/136",
                        SubName: "Batch Master"
                    },
                    {
                        id: 137,
                        SubPath: "/Home/m137/",
                        SubName: "Guardian Detail"
                    },
                ]

            },
            {
                HeaddingName: "Master Reports",
                MegaMenuSubItems: [
                    {
                        id: 125,
                        SubPath: "/Home/m125/",
                        SubName: "Master Reports"
                    },
                    {
                        id: 126,
                        SubPath: "/Home/m126/",
                        SubName: "Employee Reports"
                    },

                ]

            }


        ]
    },
    {
        MenuType: "megamenu",
        PathName: "#",
        Title: "Academic",
        LinkIcon: "school",
        MegaMenuDropDown: [
            {
                HeaddingName: "Academic",
                MegaMenuSubItems: [
                    {
                        id: 402,
                        SubPath: "/Home/acd402/",
                        SubName: "Subject Allocation"
                    },
                    {
                        id: 508,
                        SubPath: "/Home/ex508/",
                        SubName: "Bulk Allocation"
                    },
                    {
                        id: 501,
                        SubPath: "/Home/ex501/",
                        SubName: "Exam Allocation"
                    },
                    {
                        id: 502,
                        SubPath: "/Home/ex502/",
                        SubName: "Exam Marks Allocation"
                    },

                ]

            },
            {
                HeaddingName: "Academic Reports",
                MegaMenuSubItems: [
                    {
                        id: 534,
                        SubPath: "/Home/ex534/",
                        SubName: "Exam Result Settings"
                    },
                    {
                        id: 407,
                        SubPath: "/Home/acd407/",
                        SubName: "Subject Allocation List"
                    },
                    {
                        id: 530,
                        SubPath: "/Home/ex530/",
                        SubName: "Exam Allocation List"
                    },
                    {
                        id: 531,
                        SubPath: "/Home/ex531/",
                        SubName: "Exam Mark Allocation List"
                    },
                    {
                        id: 542,
                        SubPath: "/Home/ex542/",
                        SubName: "Exam Mark Status"
                    },

                ]
            }

        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Administration",
        LinkIcon: "analytics",
        DropDownSubMenu: [
            {
                id: 701,
                SubPath: "/Home/s701",
                SubName: "Sanstha Notice"
            },
        ]
    },
    {
        MenuType: "megamenu",
        PathName: "#",
        Title: "Employee Attendance",
        LinkIcon: "manage_accounts",
        MegaMenuDropDown: [
            {
                HeaddingName: "Employee Attendance",
                MegaMenuSubItems: [
                    {
                        id: 1103,
                        SubPath: "/Home/pt1103/",
                        SubName: "Employee Biometric Attendance"
                    },
                ]
            },
            {
                HeaddingName: "Report",
                MegaMenuSubItems: [
                    {
                        id: 1107,
                        SubPath: "/Home/pr1107/",
                        SubName: "Employee Attendace Report"
                    },
                ]
            }

        ]
    },
    // {
    //     id: 1103,
    //     MenuType: "normal",
    //     PathName: "/Home/pt1103/",
    //     Title: "Employee Attendance",
    //     LinkIcon: "manage_accounts"
    // },
    //maps_home_work

];

export default SansthaAcademicJSON;