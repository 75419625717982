import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { classWithDivURL, printCatalog, printCatalogMainPage } from '../Services/CatalogReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";
import YearDropdown from '../../CommonComponent/LocalJSON/YearDropdown'
import { printDailyAttendSummaryAPIURL, printStudDailyAttendReportAPIURL } from '../Services/StudentAttendanceAPIURL';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));


const DailyAttendaceSummary = () => {
    const classes = useStyles();
    const titleId = "Daily Attendance Summary";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);
    const defaultMinDate = new Date(`${authUser.sessionYear}/06/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);


    const [dateFrom, setDateFrom] = useState(null);
    const [monthId, setMonthId] = useState('');
    const [dateCheck, setDateCheck] = useState(null);
    //console.log(`date=${moment(dateFrom).format("MM/YYYY")}`)
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);




    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${classWithDivURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }


    const getStudDailyAttendSummaryReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (moment(dateFrom).format("MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            await axios.get(`${printDailyAttendSummaryAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&dateOrMonth=${moment(dateFrom).format("MM/YYYY")}`)
                .then((response) => {
                    if (response.data == "NOTFOUND") {
                        //console.log(response.data)
                        setLoaderOption(false);
                        toast.warn("Data not exists.")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)   

                        window.open(`${getMasterReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }
                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Something went wrong, try after sometime.")
                    console.log("Category Report failed: " + error)
                })
        }
        else {
            toast.error("Improper date, Please check.");
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={getStudDailyAttendSummaryReport}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={classDivData}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                value={classDivObj}
                                                onChange={(event, newvalue) => {
                                                    setClassDivObj(newvalue);
                                                    setDateFrom(null);
                                                }}

                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                                )}
                                            />
                                        </div>
                                    </div>


                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Month-year :</label>
                                        <div className='col-sm-3'>
                                            {/* <FormControl fullWidth>
                                                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Month</InputLabel>
                                                                <Select
                                                                    size='small'
                                                                    required
                                                                    fullWidth
                                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                                    margin='dense'
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={monthId}
                                                                    label="Select Month"
                                                                    onChange={(e) => {
                                                                        setMonthId(e.target.value);
                                                                    }}
                                                                >
                                                                    {YearDropdown.map((item, index) => {
                                                                        if (item.id >= 6 && item.id <= 12) {
                                                                            return (
                                                                                <MenuItem value={item.id} key={index}>{item.name}   {authUser.sessionYear}</MenuItem>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <MenuItem value={item.id} key={index}>{item.name}   {(authUser.sessionYear) + 1}</MenuItem>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Select>
                                                            </FormControl> */}
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Month-year"
                                                    value={dateFrom}
                                                    minDate={defaultMinDate}
                                                    maxDate={defaultMaxDate}
                                                    views={['year', 'month']}
                                                    inputFormat="MM/yyyy"
                                                    mask="__/____"
                                                    disableMaskedInput={false}
                                                    onChange={(newValue) => {
                                                        setDateFrom(newValue);
                                                        //console.log(moment(newValue).format("MM/YYYY"))
                                                        (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>


                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type="submit" className="btn btn-primary btn-sm" >Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}


export default DailyAttendaceSummary
