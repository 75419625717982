import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const getClassDivisionURL = BaseURLMaster + "classWithDivisionBySectionId/";

export const BaseURLStudentAttendance = BaseURL + "academics/catlog/";
export const studentAttendanceGetMapping = BaseURLStudentAttendance + "getStudentMonthlyAttendance";
export const studentAttendancePostMapping = BaseURLStudentAttendance + "saveStudentMonthlyAttendance/"



// ------------ Student Attendance -----------


// /institute/sss/academics/catlog/

// GetMapping     /getStudentMonthlyAttendance

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cid") long classId,@RequestParam("did") long divisionId,@RequestParam("mid") long monthId
// ------------------------------------------------------------

// PostMapping   /saveStudentMonthlyAttendance

//  private long regCode;
//     private String  studentNameMr;
//     private String  studentNameEn;
//     private  long genderId;
//     private  long rollNo;
//     private  long presentDays;
//     private  long monthId;
//     private long classId;
//     private long divisionId;
//     private long sessionYear;
//     private long sectionId;
//     private long branchId;



//Student Daily Attendace API
//http://192.168.29.102:8081/institute-sss/institute/sss/academics/studentAttendance/getStudentDailyAttendance?acyr=2023&sid=3&bid=1&cid=5&did=17&date=2023-03-01
export const getStudentDailyAttendaceAPIURL = BaseURL + "academics/studentAttendance/getStudentDailyAttendance";



// /http://192.168.29.102:8081/institute-sss/institute/sss/academics/studentAttendance/saveStudentDailyAttendance

// [
//     {
//         "regCode": 13005,
//         "flag": 1,
//         "date": "2023-03-01",
//         "classId": 5,
//         "divisionId": 17,
//         "sessionYear": 2023,
//         "sectionId": 3,
//         "branchId": 1
//     }
// ]
export const saveStudentDailyAttendaceAPIURL = BaseURL + "academics/studentAttendance/saveStudentDailyAttendance";



//Student Attendace Report APIURL
// /http://192.168.29.102:8081/institute-sss/institute/sss/academic/reports/attendance/getStudentDailyAttendanceReport?langId=1&classId=5&divId=17&option=2&dateOrMonth=07/2024&year=2023&sid=3&bid=1
export const printStudDailyAttendReportAPIURL = BaseURL + "academic/reports/attendance/getStudentDailyAttendanceReport";


//Daily Attendance summary api
//getStudentDailyAttendanceSummary
export const printDailyAttendSummaryAPIURL = BaseURL + "academic/reports/attendance/getStudentDailyAttendanceSummary";
