import React from 'react';
import { Button, Modal } from 'react-bootstrap';
//Note here this modal box require two parameters i.e ID and DeleteData Function
const StudRegUpdateModal = (props) => {
    // console.log('Rendering DeleteModalBox');
    // console.log('Props:', props);
    return (
        <>
            <Modal show={props.modal}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to update?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setModal(false)}>Close</Button>
                    <Button variant="danger" onClick={() => props.updateStudRegConfirmBox()}>Update</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StudRegUpdateModal
