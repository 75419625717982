import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import SortIcon from '@mui/icons-material/Sort';
import { getStudentListRollNo, getClassWithDiv, updateRollNumAPIURS, saveShiftStudentsAPIURL, saveManualRollNumAPIURL, saveManualStudentRollNoAssignDataReassignAPIURL, StudentsForManualRollNo } from '../Services/StudentRollNoAPIURL';
import RollNoAssignTextBox from './RollNoAssignTextBox';
import Loader from '../../CommonComponent/Loader';
import { getStudentList } from '../../FeeManagement/Services/FeeReceiptAPIURL';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { getStudentListByClass } from '../../Administration/Services/BonafideAPIURL'
import { BaseURLMasterCasteCategory } from "../../CommonServices/CasteCategoryMasterAPIURL"

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    }
});
const RollNoAssign = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Roll No Assignment";
    const [loaderOption, setLoaderOption] = useState(false);

    const classes = useStyles();

    const TableHeading = [{ label: 'Reg No', key: 'regCode' }, { label: 'Category', key: (authUser.branchMedium == 1) ? "categoryNameMr" : "categoryNameEn" }, { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' }, { label: `Gender`, key: 'genderLabel' }, { label: `Roll No`, key: 'rollNo' }];

    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [shiftdivisionData, setShiftDivisionData] = useState([]);

    const [shiftClassDivObj, setShiftClassDivObj] = useState(null);

    //console.log("division data: "+JSON.stringify(divisionData));
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [toClassName, setToClassName] = useState("");
    const [fromClassName, setFromClassName] = useState("");

    //console.log(searchTerm)
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [currentClass, setCurrentClass] = useState("");
    const [manualRollNo, setManualRollNo] = useState('');
    const [tempData, setTempData] = useState([]);
    const [rollNum, setRollNum] = useState('');
    const [regNum, setRegNum] = useState('');

    //console.log("classDiv ClassId======"+classDivObj.divisionId+"student ClassId"+classDivObj.classId)

    //total student count
    const [studentCount, setStudentCount] = useState(0)
    //filterOption start
    const filterTypeData = [
        {
            id: 1,
            name: "Gender"
        },
        {
            id: 2,
            name: "Category"
        }
    ]

    const [filterTypeObj, setFilterTypeObj] = useState(null);
    const [filterTypeObjid, setFilterTypeObjid] = useState(0)
    const [categoryData, setCategoryData] = useState([]);
    const [categoryObj, setCategoryObj] = useState(null);
    const [gender, setGender] = useState(1);

    //console.log(JSON.stringify(categoryData))

    //filterOption end

    //group shifting states
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [dataLeft, setDataLeft] = useState([]);
    const [leftFilteredData, setLeftFilteredData] = useState([]);
    const [leftSearchTerm, setLeftSearchTerm] = useState([]);
    const [right, setRight] = useState([]);
    const [rightFilteredData, setRightFilteredData] = useState([]);
    const [rightSearchTerm, setRightSearchTerm] = useState([]);
    const [rollNoCheck, setRollNoCheck] = useState("");
    const [showModal, setShowModal] = useState(false);
    //console.log(JSON.stringify(right))

    //transfer list functionality
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    //console.log(JSON.stringify(leftChecked))

    const getDataForShift = async (dId) => {
        //setLoaderOption(true);
        await axios(`${getStudentListRollNo}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${dId}`)
            .then(res => {
                //console.log(res.data);
                setLeft(res.data);
                setDataLeft(res.data);
                setLeftFilteredData(res.data);
                //setLoaderOption(false);
            })
    }

    const handleToggle = (value) => () => {
        //console.log(value);    
        const currentIndex = checked.indexOf(value);
        //console.log("Data="+currentIndex)       
        const newChecked = [...checked];

        if (currentIndex === -1) {
            //console.log("Checked="+JSON.stringify(value.id))           
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        //console.log(newChecked.map(e=>e.id).join(','))
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setRightFilteredData(right.concat(left));
        setLeft([]);
        setLeftFilteredData([]);
        setLeftSearchTerm("");
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setRightFilteredData(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setLeftFilteredData(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        setLeftSearchTerm("");
        // leftChecked.map(e=>{
        //     if(e.rollNo > 0){
        //         setRollNoCheck("NonZero");                
        //     }
        // })     
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setLeftFilteredData(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setRightFilteredData(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        setRightSearchTerm("");
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setLeftFilteredData(left.concat(right));
        setRight([]);
        setRightFilteredData([]);
        setRightSearchTerm("");
    };


    useEffect(() => {
        setLeftFilteredData(
            left.filter((item) =>
                item.regCode.toString().toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                item.studentNameMr.toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(leftSearchTerm.toLowerCase())
            ))
    }, [leftSearchTerm])

    useEffect(() => {
        setRightFilteredData(
            right.filter((item) =>
                item.regCode.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                item.studentNameMr.toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(rightSearchTerm.toLowerCase())
            ))
    }, [rightSearchTerm])


    const leftListData = (leftdata) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>

            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "200px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={leftSearchTerm}
                        onChange={e => setLeftSearchTerm(e.target.value)}
                    /></span>
                </div>

                {leftdata.sort((a, b) => a.regCode > b.regCode ? 1 : -1).map((value) => {
                    const labelId = value.regCode;
                    return (
                        <ListItem
                            key={value.regCode}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.regCode + " - " + ((authUser.branchMedium == 1) ? value.studentNameMr : value.studentNameEn)} primaryTypographyProps={{ fontSize: '14px', fontWeight: "540" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const rightListData = (rightData) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>
            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "200px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={rightSearchTerm}
                        onChange={e => setRightSearchTerm(e.target.value)}
                    /></span>
                </div>
                {rightData.sort((a, b) => a.regCode > b.regCode ? 1 : -1).map((value) => {
                    const labelId = value.regCode;
                    return (
                        <ListItem
                            key={value.regCode}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.regCode + " - " + ((authUser.branchMedium == 1) ? value.studentNameMr : value.studentNameEn)} primaryTypographyProps={{ fontSize: '14px', fontWeight: "540" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );



    useEffect(() => {
        getData();
    }, [authUser]);

    //getdata of students for manual assign and class-div 
    const getData = async () => {

        setLoaderOption(true);
        await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setDivisionData(res.data);
                setLoaderOption(false);
            })

        await axios.get(BaseURLMasterCasteCategory)
            .then((res) => {
                if (res.data !== null) {
                    setCategoryData(res.data)
                    setCategoryObj(res.data[0])
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    const getClassDivForShift = async (cId, dId) => {
        setLoaderOption(true);
        await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {

                const tempData = (res.data).filter(e => e.classId === cId);
                const finalData = tempData.filter(e => e.divisionId !== dId)
                //console.log("finalData==="+JSON.stringify(finalData));
                setShiftDivisionData(finalData);
                setLoaderOption(false);
            })
    }

    // const [studentsManualAssign, setStudentsManualAssign] = useState([]);
    const getStudentForManualAssign = async (cId, dId) => {
        //console.log(`${StudentsForManualRollNo}?sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${currentClassId}&did=${currentDivId}&sesyear=${authUser.sessionYear}`)
        await axios(`${StudentsForManualRollNo}?sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${cId}&did=${dId}&sesyear=${authUser.sessionYear}`)
            .then(res => {
                setStudentData(res.data);
                setLoaderOption(false);
            })
    }


    const [filteredData, setFilteredData] = useState([]);
    const onSort = (key, isAction) => {

        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
            //console.log("Data============" + JSON.stringify(data))

        }
        setFilteredData(data);
        setData(data);
        getSortedData(data, key, isAction);
    }

    //for table sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentListRollNo}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${classDivObj.divisionId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            })
    }

    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            data.filter((item) =>
                item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.categoryNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.categoryNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.genderLabel.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(tempFilteredData)
        //setFilteredData(data.filter((item) => item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.categoryNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.genderLabel.toLowerCase().includes(searchTerm.toLowerCase()) || item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) || item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    }, [searchTerm])


    //getdata for table
    const getDivisionData = async (divId) => {
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        //let link = getStudentListRollNo + "?acyr=" + authUser.sessionYear + "&sid=" + authUser.deptId + "&bid=" + authUser.branchId + "&cdid=" + divId;
        //console.log(link);
        await axios(`${getStudentListRollNo}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${divId}`)
            .then(res => {
                console.log(JSON.stringify(res.data))
                if (res.data.length == 0) {
                    setLoaderOption(false);
                    toast.warn("Students not found for this class-div.")
                }
                else {
                    let rollNoData = res.data;
                    let nonZeroSortData = rollNoData.filter((e) => e.rollNo !== 0)
                    setStudentCount(nonZeroSortData.length);
                    setData(res.data);
                    setFilteredData(res.data);
                    setTempData(res.data);
                    setLoaderOption(false);
                }

            })
    }


    useEffect(() => {
        let updatedData = tempData;

        tempData.map((e, index) => {
            if (e.regCode == regNum) {
                updatedData[index] = ({ ...e, rollNo: rollNum })
            }
        })
        //console.log(JSON.stringify(tempData))
        if (searchTerm == "") {
            setData(tempData);
            setFilteredData(tempData);
        }
    }, [regNum, searchTerm])

    // const [chkRno, setCheckRno] = useState(false);
    // console.log(chkRno)

    //save roll no inside table
    const saveRollNum = (e) => {
        setLoaderOption(true);
        e.preventDefault();
        const newStudData = data.filter(e => e.rollNo != 0)

        console.log("rollNum===" + rollNum + "regNum===" + regNum)

        const rnoNew = newStudData.find((item, ind) => {
            if (item.rollNo == rollNum && item.regCode !== regNum) {
                return item;
            }
        })

        console.log(rnoNew)

        if (!rnoNew) {
            axios.post(`${updateRollNumAPIURS}`, filteredData)
                .then((response) => {
                    if (response.data == "SAVED") {
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setClassDivObj(null);
                        setLoaderOption(false);
                        toast.success("Roll number updation sucessfully done.");
                    }

                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                })

        }
        else {
            setLoaderOption(false);
            console.log(JSON.stringify(data))
            // setRollNum('');
            // setRegNum('');
            toast.warn("Roll No " + rollNum + " already exists.")

        }
    }


    //save Manual Roll No assign
    const addRollNoManually = async () => {
        const filter = filteredData.filter(e => e.classId == classDivObj.classId && e.divisionId == classDivObj.divisionId)
        const afterFilter = filter.find(e => e.rollNo == manualRollNo)
        //console.log(afterFilter)
        document.body.style.overflow = "auto";
        if (classDivObj.classId !== studentObj.currentClassId) {
            toast.warn("Current class is not match with selected class.")
        }
        else if (manualRollNo == 0) {
            toast.warn("Roll no should be greater than zero.")
        }
        else if (afterFilter) {
            toast.warn(`Roll No ${manualRollNo} already exists.`)
        }
        else {
            setLoaderOption(true);
            const saveObject = [{
                regCode: studentObj.regCode,
                studentNameMr: studentObj.studentNameMr,
                studentNameEn: studentObj.studentNameEn,
                categoryNameMr: studentObj.categoryNameMr,
                categoryNameEn: studentObj.categoryNameEn,
                genderLabel: (studentObj.genderId == 1) ? "M" : "F",
                rollNo: manualRollNo,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
                classId: classDivObj.classId,
                divisionId: classDivObj.divisionId
            }]
            //console.log(JSON.stringify(saveObject))
            axios.post(`${saveManualRollNumAPIURL}`, saveObject)
                .then((response) => {
                    if (response.data == "SAVED") {
                        setFilteredData([]);
                        setData([]);
                        setClassDivObj(null);
                        setLoaderOption(false);

                        // get modal
                        const modal = document.getElementById('manualRollNoAssign');

                        // change state like in hidden modal
                        if (modal.classList != null) {
                            modal.classList.remove('show');
                            modal.setAttribute('aria-hidden', 'true');
                            modal.setAttribute('style', 'display: none');
                        }

                        // get modal backdrop
                        const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                        // remove opened modal backdrop
                        document.body.removeChild(modalBackdrops[0]);
                        toast.success("Roll number updation sucessfully done.");
                    }

                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                })
            //toast.success("Success")
        }
    }

    //add and renumber roll no
    const addRenumberFun = async () => {
        document.body.style.overflow = "auto";
        if (classDivObj.classId !== studentObj.currentClassId) {
            toast.warn("Current class is not match with selected class.")
        }
        else if (manualRollNo == 0) {
            toast.warn("Roll no should be greater than zero.")
        }
        else {
            setLoaderOption(true);
            const saveObject = [{
                regCode: studentObj.regCode,
                studentNameMr: studentObj.studentNameMr,
                studentNameEn: studentObj.studentNameEn,
                categoryNameMr: studentObj.categoryNameMr,
                categoryNameEn: studentObj.categoryNameEn,
                genderLabel: (studentObj.genderId == 1) ? "M" : "F",
                rollNo: manualRollNo,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
                classId: classDivObj.classId,
                divisionId: classDivObj.divisionId
            }]
            await axios.post(saveManualStudentRollNoAssignDataReassignAPIURL, saveObject)
                .then((response) => {
                    if (response.data == "SAVED") {
                        setFilteredData([]);
                        setData([]);
                        setClassDivObj(null);
                        setLoaderOption(false);

                        // get modal
                        const modal = document.getElementById('manualRollNoAssign');

                        // change state like in hidden modal
                        if (modal.classList != null) {
                            modal.classList.remove('show');
                            modal.setAttribute('aria-hidden', 'true');
                            modal.setAttribute('style', 'display: none');
                        }

                        // get modal backdrop
                        const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                        // remove opened modal backdrop
                        document.body.removeChild(modalBackdrops[0]);
                        toast.success("Roll number updation sucessfully done.");
                    }

                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                })
        }
    }

    const state = {
        button: 0
    };
    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            addRollNoManually();
        }
        if (state.button === 2) {
            addRenumberFun();
        }
    };

    const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState('')
    const shiftStudents = async (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        setLoaderOption(true);
        let saveObj = right;
        right.map((e, index) => {
            saveObj[index] = ({
                "id": e.id,
                "regCode": e.regCode,
                "studentNameMr": e.studentNameMr,
                "categoryNameMr": e.categoryNameMr,
                "studentNameEn": e.studentNameEn,
                "categoryNameEn": e.categoryNameEn,
                "genderLabel": e.genderLabel,
                "rollNo": 0,
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId,
                "classId": classDivObj.classId,
                "divisionId": classDivObj.divisionId,
            })
        })
        //console.log(JSON.stringify(saveObj))
        await axios.post(saveShiftStudentsAPIURL, saveObj)
            .then((res) => {
                if (res.data !== null) {
                    setFilteredData([]);
                    setData([]);
                    setClassDivObj(null);
                    setLoaderOption(false);

                    // get modal
                    const modal = document.getElementById('groupShifting');

                    // change state like in hidden modal
                    if (modal.classList != null) {
                        modal.classList.remove('show');
                        modal.setAttribute('aria-hidden', 'true');
                        modal.setAttribute('style', 'display: none');
                    }

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                    // remove opened modal backdrop
                    document.body.removeChild(modalBackdrops[0]);
                    toast.success("Student shifts successfully.")
                }
            }).catch(error => {
                console.log("Update Fire Query: " + error)
            })

    }

    const filterFun = (e) => {
        //console.log(gender)
        e.preventDefault()
        if (filterTypeObjid === 1) {
            let newData1;
            let newData2;
            if (gender == 1) {
                newData1 = tempData.filter((e) => e.genderLabel === "M")
                newData2 = tempData.filter((e) => e.genderLabel !== "M")
                setData(newData1.concat(newData2))
                setFilteredData(newData1.concat(newData2))
                setTempData(newData1.concat(newData2))
                document.getElementById("modalDismissBtn").click()
            }
            else if (gender == 2) {
                newData1 = tempData.filter((e) => e.genderLabel === "F")
                newData2 = tempData.filter((e) => e.genderLabel !== "F")
                setData(newData1.concat(newData2))
                setFilteredData(newData1.concat(newData2))
                setTempData(newData1.concat(newData2))
                document.getElementById("modalDismissBtn").click()
            }
        }
        else if (filterTypeObjid === 2) {
            let newData1;
            let newData2;
            if (categoryObj !== null) {
                newData1 = tempData.filter((e) => e.categoryNameMr === categoryObj.nameMr)
                newData2 = tempData.filter((e) => e.categoryNameMr !== categoryObj.nameMr)
                setData(newData1.concat(newData2))
                setFilteredData(newData1.concat(newData2))
                setTempData(newData1.concat(newData2))
                document.getElementById("modalDismissBtn").click()
            }
            else {
                toast.warn("Select Category")
            }
        }
    }

    const [studentCounter, setStudentCounter] = useState(0)
    useEffect(() => {
        setStudentCount(0);
        let sortNonZero = tempData.filter((e) => e.rollNo != 0);
        setStudentCount(sortNonZero.length);
    }, [studentCounter])


    const handelkeyPress = (event) => {
        if (event.key === "Enter") {
            if (data !== null || data != "") {
                event.preventDefault();
                let btn = document.querySelector('.myRollNoBtn')
                if (btn !== null) {
                    console.log('Enter is pressed!');
                    btn.click();
                }
            }
        }
    };

    useEffect(() => {
        console.log('useEffect Called');
        document.addEventListener("keypress", handelkeyPress);

        return () => {
            // remove the event handelkeyPress
            document.removeEventListener("keypress", handelkeyPress);
        };
    }, []);


    if (loaderOption == true) {
        return <Loader />
    }
    else {

        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <div className='row'>
                        <div className='col-sm-3'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={divisionData}
                                style={{ fontSize: '14px' }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {

                                    //console.log(newValue.classId + " " + newValue.divisionId);
                                    setClassDivObj(newValue);
                                    if (newValue != null) {
                                        getDivisionData(newValue.divisionId);
                                        setFromClassName(newValue.classNameEn + "" + newValue.divisionNameEn);
                                        getStudentForManualAssign(newValue.classId, newValue.divisionId);
                                        getClassDivForShift(newValue.classId, newValue.divisionId);
                                    }
                                    else if (newValue == null) {
                                        setFilteredData([]);
                                        setData([]);
                                    }
                                    setAscending(false);
                                    setSortedBy(null);
                                    setSearchTerm("");
                                    setRight([]);
                                    setRightFilteredData([]);
                                }}
                                value={classDivObj}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <div className="col-sm-8 mt-1">
                            <button type="button" disabled={(classDivObj == null || classDivObj == "") ? true : false} onClick={() => { setStudentObj(null); setCurrentClass(""); setManualRollNo("") }} style={{ margin: '0px 4px' }} data-bs-toggle="modal" data-bs-target="#manualRollNoAssign" className="btn btn-sm btn-primary">Manual Assign</button>
                            <button type="button" disabled={(classDivObj == null || classDivObj == "") ? true : false} onClick={() => { setShiftClassDivObj(null); setLeftFilteredData([]); setDataLeft([]); }} style={{ margin: '0px 4px' }} data-bs-toggle="modal" data-bs-target="#groupShifting" className="btn btn-sm btn-warning">Group Shifting</button>

                            <button type="button"
                                disabled={(classDivObj == null || classDivObj == "") ? true : false}
                                style={{ margin: '0px 4px', backgroundColor: "#3FB837", color: "#fff" }}
                                data-bs-toggle="modal" data-bs-target="#filterOption"
                                className="btn btn-sm p-1">

                                <SortIcon fontSize='small' /> Filter Data
                            </button>
                        </div>


                    </div>
                    {
                        (data == null || data == "") ?
                            <h4 className='mt-5'>No records found, Select Class-Division</h4>
                            :
                            <div>
                                <div className="row my-1">
                                    <div className="col-sm-12">
                                        <h5 style={{ float: 'right', textAlign: "right" }}>Total Strength : {studentCount}</h5>
                                    </div>
                                    <div className='col-sm-12' style={{ float: 'right' }}>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>
                                <form onSubmit={saveRollNum}>
                                    <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                        <table className="table table-bordered ">
                                            <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => { onSort(item.key, item.isAction) }} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}

                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData
                                                    .map((item, index) => {
                                                        return (

                                                            <tr key={index}>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regCode}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.categoryNameMr : item.categoryNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.genderLabel}</td>

                                                                <RollNoAssignTextBox initialText={item.rollNo} checkData={data} rCode={item.regCode}
                                                                    onChange={(value) => {
                                                                        let updatedData = filteredData;
                                                                        updatedData[index] = ({ ...item, rollNo: value });
                                                                        setFilteredData(updatedData);
                                                                        setData(updatedData);
                                                                        setRollNum(value);
                                                                        setRegNum(updatedData[index].regCode)
                                                                        //console.log(JSON.stringify(updatedData[index].regCode));

                                                                        setStudentCounter(studentCounter + 1)
                                                                    }} />

                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='mt-4'>
                                        <button type='submit' className='myRollNoBtn btn btn-primary btn-sm mb-2' onClick={() => setSearchTerm("")} ><AddIcon fontSize="small" />Save Changes</button>
                                    </div>
                                </form>
                            </div>
                    }


                </div>

                <div className="modal fade" id="manualRollNoAssign" tabIndex="-1" aria-labelledby="manualRollNoAssignLabel" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: "#EAECEE" }}>
                                <h4 className="modal-title" id="manualRollNoAssignLabel">Manual Roll No Assign</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={onSubmit}>
                                <div className="modal-body">

                                    <div className="row">
                                        <label className="col-sm-4" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Student:</label>
                                        <div className='col-sm-8'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={studentData}
                                                style={{ fontSize: '14px' }}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                value={studentObj}
                                                onChange={(event, newValue) => {
                                                    setStudentObj(newValue);
                                                    setManualRollNo('');
                                                    //console.log(JSON.stringify(newValue));
                                                    if (newValue == null) {
                                                        setCurrentClass("");
                                                        setManualRollNo('');
                                                    }
                                                    else {
                                                        setCurrentClass((authUser.branchMedium == 1) ? newValue.currentClassMr + " " + newValue.currentDivisionMr : newValue.currentClassEn + " " + newValue.currentDivisionEn)
                                                    }

                                                }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + " " + option.currentClassMr + " " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + " " + option.currentClassEn + " " + option.currentDivisionEn}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className="col-sm-4" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Current Class :</label>
                                        <div className='col-sm-6'>
                                            <TextField
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                className=''
                                                size='small'
                                                id="outlined-basic"
                                                label="Current Class"
                                                variant="outlined"
                                                margin='dense'
                                                value={currentClass}
                                                onChange={(e) => setCurrentClass(e.target.value)}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ readOnly: true }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className="col-sm-4" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Roll No :</label>
                                        <div className='col-sm-6'>
                                            <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                autoComplete='off'
                                                className=''
                                                size='small'
                                                id="outlined-basic"
                                                label="Roll No"
                                                variant="outlined"
                                                margin='dense'
                                                value={manualRollNo}
                                                onChange={(e) => setManualRollNo(e.target.value)}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-warning" onClick={() => (state.button = 2)}>Add & Re-number</button>
                                    <button type="submit" className="btn btn-primary" onClick={() => (state.button = 1)}>Add</button>
                                    <button type="reset" className="btn btn-danger" data-bs-dismiss="modal">Cancel</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="groupShifting" tabIndex="-1" aria-labelledby="groupShiftingLabel" aria-hidden="true" data-backdrop="false" >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: "#EAECEE" }}>
                                <h4 className="modal-title" id="groupShiftingLabel">Group Shifting</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={shiftStudents}>
                                <div className="modal-body" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                    <div className="row">
                                        <div className='col-sm-6'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={shiftdivisionData}
                                                style={{ fontSize: '14px' }}
                                                value={shiftClassDivObj}
                                                onChange={(event, newValue) => {
                                                    setShiftClassDivObj(newValue);
                                                    setLeft([]);
                                                    setDataLeft([]);
                                                    setLeftFilteredData([]);
                                                    setRight([]);
                                                    setRightFilteredData([]);
                                                    getDataForShift(newValue.divisionId);
                                                    setToClassName(newValue.classNameEn + "" + newValue.divisionNameEn);
                                                }}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Class-division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    {
                                        (dataLeft == null || dataLeft == "" || dataLeft == [])
                                            ?
                                            <h5 className='mt-4'>Data not found, select class-division</h5>
                                            :
                                            <div>
                                                {/* <div className="row mt-3">
                                                    <div className='col-sm-12'> */}
                                                <Grid container spacing={2} alignItems="center"  >
                                                    <Grid item >{leftListData(leftFilteredData)}</Grid>
                                                    <Grid item>
                                                        <Grid container direction="column" alignItems="center"  >
                                                            {/* <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllRight}
                                                                        disabled={left.length === 0}
                                                                        aria-label="move all right"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        ≫
                                                                    </button> */}
                                                            <button
                                                                className='my-1'
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleCheckedRight}
                                                                //data-bs-toggle={(rollNoCheck == "NonZero") ? "modal" : ""} 
                                                                //data-bs-target={(rollNoCheck == "NonZero") ? "#confirmShiftModal" : ""} 
                                                                disabled={leftChecked.length === 0}
                                                                aria-label="move selected right"
                                                                style={{ width: "30px" }}
                                                            >
                                                                &gt;
                                                            </button>
                                                            <button
                                                                className='my-1'
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleCheckedLeft}
                                                                disabled={rightChecked.length === 0}
                                                                aria-label="move selected left"
                                                                style={{ width: "30px" }}
                                                            >
                                                                &lt;
                                                            </button>
                                                            {/* <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllLeft}
                                                                        disabled={right.length === 0}
                                                                        aria-label="move all left"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        ≪
                                                                    </button> */}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>{rightListData(rightFilteredData)}</Grid>
                                                </Grid>
                                                {/* </div>
                                                </div> */}
                                            </div>
                                    }

                                </div>
                                <div className="modal-footer">
                                    <button type="submit" disabled={(shiftClassDivObj == null || shiftClassDivObj == "" || right == [] || right == "" || right == null) ? true : false} className="btn btn-primary">Shift</button>
                                    <button type="reset" className="btn btn-danger" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>


                {/* <div className="modal fade" id="confirmShiftModal" tabIndex="-1" aria-labelledby="confirmShiftModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header mt-3">
                                <h4 className="modal-title" id="confirmShiftModalLabel">Are you sure to shift   {totalSelectedCheckboxes}   students from   {fromClassName}  to  {toClassName}  class?</h4>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={() => confirmShiftStudent()} data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <Modal show={modal}>
                    <Modal.Header>
                        <Modal.Title>Are you sure to shift   {totalSelectedStudents}   students from   {toClassName}   to   {fromClassName}   class??</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModal(false)}>Close</Button>
                        <Button variant="danger" onClick={shiftStudents}>Shift</Button>
                    </Modal.Footer>
                </Modal> */}

                {/* Filter Option */}
                <div className="modal fade" id="filterOption" tabIndex="-1" aria-labelledby="filterOptionLabel" aria-hidden="true">
                    <form onSubmit={filterFun}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header  p-2" style={{ backgroundColor: "#EAECEE" }}>
                                    <h4 className="modal-title" id="FilterOptionLabel">Filter Option</h4>
                                    <button type="button" style={{ marginTop: "-4px" }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body px-2 py-2">
                                    <div className="row">
                                        <label className="col-sm-3" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Filter Type:</label>
                                        <div className='col-sm-6'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={filterTypeData}
                                                style={{ fontSize: '14px' }}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                value={filterTypeObj}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        setFilterTypeObj(newValue);
                                                        setFilterTypeObjid(newValue.id)
                                                    }
                                                    else {
                                                        setFilterTypeObj(null);
                                                        setFilterTypeObjid(null)
                                                    }
                                                }}
                                                getOptionLabel={option => option.name}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Filter Type" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    {(() => {
                                        if (filterTypeObjid === 1) {
                                            return (

                                                <div className="row">
                                                    <label className="col-sm-3 my-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Gender:</label>
                                                    <div className='col-sm-9'>
                                                        <RadioGroup row aria-label="gender" name="customized-radios" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                            <FormControlLabel value={1} control={<Radio color='primary' />} label="Male" />
                                                            <FormControlLabel value={2} control={<Radio color='primary' />} label="Female" />
                                                        </RadioGroup>
                                                    </div>
                                                </div>

                                            )
                                        }
                                        else if (filterTypeObjid === 2) {
                                            return (

                                                <div className="row">
                                                    <label className="col-sm-3" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Category:</label>
                                                    <div className='col-sm-6'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={categoryData}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            value={categoryObj}
                                                            onChange={(event, newValue) => {
                                                                setCategoryObj(newValue);
                                                            }}
                                                            getOptionLabel={option => option.nameMr}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin="dense" label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                            )
                                        }
                                    })()}

                                </div>
                                <div className="modal-footer">
                                    <button type="button" id='modalDismissBtn' className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" onClick={() => { setFilteredData([]); setData([]) }} className="btn btn-primary">Filter</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>


                {/* <ToastContainer position="top-right" theme="colored" /> */}

            </>
        )

    }
}

export default RollNoAssign;