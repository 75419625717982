import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../CommonComponent/TitleLabel';
import Loader from '../../CommonComponent/Loader';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import axios from 'axios';
import { deleteDualSideVoucherAPIURL, deleteSingleSideVoucherAPIURL, getAccClosingBalAmtAPIURL, getClosingBalanceAmtAPIURL, getDualSideAccountClosingBalanceAPI, getDualSideClosingBalanceAPI, getDualSideVoucherNoDataAPI, VoucherEntryGetData, VoucherEntryNoGetMapping } from '../../CommonServices/VoucherEntryAPIURL';
import { BaseURLNarrationMaster } from '../../CommonServices/NarrationMasterAPIURL';
import { BaseURLAccountMaster } from '../../CommonServices/AccountMasterAPIURL';
import transactionModeData from '../../CommonComponent/LocalJSON/TransactionModeData';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReceiptImage from '../Modal/r1.png';
import ChalanImage from '../Modal/c1.png';
import { styled } from '@mui/material/styles';
import makeStyles from "@mui/styles/makeStyles";
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import CancelVoucherModalBox from './VoucherDeleteModal/CancelVoucherModalBox';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
}));

// Custom styling to hide the dropdown icon
const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-popupIndicator': {
        display: 'none',
    },
    '& .MuiAutocomplete-clearIndicator': {
        display: 'none',
    },
});

const CancelVoucher = () => {
    const classes = useStyles();
    const titleId = "Cancel Voucher";
    let disableEditFlag = sessionStorage.getItem('FormProcessingFlag'); // Assuming it's a string
    disableEditFlag = JSON.parse(disableEditFlag); // Parse string to object
    // console.log(disableEditFlag + "=======" + disableEditFlag[5])
    const authUser = useSelector((state) => state.user.value);

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);

    const [loaderOption, setLoaderOption] = useState(false);
    const [voucherNo, setVoucherNo] = useState("");

    const [hideBtn, setHideBtn] = useState(true);

    const [accountData, setAccountData] = useState([]);
    const [accountDataTo, setAccountDataTo] = useState([]);

    const [narrationData, setNarrationData] = useState([]);
    const [DateValue, setDateValue] = useState(null);
    //new Date(`${authUser.sessionYear}/04/01`)

    const [voucherId, setVoucherId] = useState([]);
    const [amount, setAmount] = useState(null);
    const [narration, setNarration] = useState("");
    const [isNarrationSave, setIsNarrationSave] = useState(0);
    const [transactionMode, setTransactionMode] = useState(1);
    const [isReceipt, setIsReceipt] = useState(2);
    const [id, setId] = useState();
    const [accountCode, setAccountCode] = useState(null);
    const [accountCodeObj, setAccountCodeObj] = useState(null);

    const [closingAmt, setClosingAmt] = useState(0);

    const [accClosingAmt, setAccClosingAmt] = useState('');
    const [accClosingFlag, setAccClosingFlag] = useState('');

    const [accountCode2, setAccountCode2] = useState(null);
    const [accountCodeObj2, setAccountCodeObj2] = useState(null);
    // console.log(accountCodeObj2)
    const [formType, setFormType] = useState(null);

    const [voucherDataMaster, setVoucherDataMaster] = useState([])
    const [crAmount, setCrAmount] = useState(0)
    const [drAmount, setDrAmount] = useState(0)

    const HandleNarrationCheckbox = (e) => {
        setIsNarrationSave(e.target.checked);
        if (e.target.checked == true) {
            setIsNarrationSave(1);
        }
        else {
            setIsNarrationSave(0);
        }
    }

    const getSingleVoucherEntry = async () => {
        setLoaderOption(true);

        await axios(`${VoucherEntryGetData}?vno=${voucherNo}&sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
            .then(respOne => {
                //console.log(JSON.stringify(respOne.data.voucherId))
                if (respOne.data.voucherId == voucherNo) {
                    setDateValue(moment(respOne.data.voucherDate).format("DD/MM/YYYY"));
                    //console.log("Date====="+DateValue).
                    setNarration(respOne.data.narration);
                    setTransactionMode(respOne.data.transactionMode);
                    setAmount(respOne.data.transactionMode == 1 ? respOne.data.creditAmount : respOne.data.debitAmount)
                    setIsReceipt(respOne.data.isReceipt);
                    setIsNarrationSave(respOne.data.isNarrationSave);
                    const SelectedAccount = respOne.data.accountCode;  



                    axios.get(`${BaseURLNarrationMaster}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then((respThree) => {
                            setNarrationData(respThree.data);
                            // setLoaderOption(false);
                        })

                    axios.get(`${getClosingBalanceAmtAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            setClosingAmt(res.data);
                            // setLoaderOption(false);
                        })

                    axios.get(`${getAccClosingBalAmtAPIURL}?accCode=${SelectedAccount}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            console.log(res.data);
                            setAccClosingAmt(parseFloat(res.data.closing).toFixed('2'));
                            setAccClosingFlag(res.data.flag)
                            // setLoaderOption(false);

                        })

                    axios.get(`${BaseURLAccountMaster}?sid=${authUser.deptId}&acyr=${authUser.sessionYear}&bid=${authUser.branchId}`)
                        .then((respTwo) => {
                            setAccountData((respTwo.data).sort((a, b) => a.accountCode > b.accountCode ? 1 : -1));
                            setAccountCodeObj(respTwo.data.find(e => e.accountCode == SelectedAccount));
                            setAccountCode(SelectedAccount);
                            setLoaderOption(false);
                        })
                }
                else {
                    setLoaderOption(false);
                    toast.warn("Voucher Number is not exists.")
                }
            })
    }

    const getDualVoucherEntry = async () => {
        setLoaderOption(true);
        const secondUrl = `${getDualSideVoucherNoDataAPI}?vno=${voucherNo}&sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`;
        console.log(secondUrl);
        await axios(`${secondUrl}`)
            .then(respOne => {
                if (Array.isArray(respOne.data)) {
                    if (respOne.data[0].voucherType == 3) {
                        setVoucherDataMaster(respOne.data)
                        setVoucherId(respOne.data[0].voucherId);
                        setFormType(respOne.data[0].voucherType);
                        if (respOne.data.length > 0) {
                            let crAmount1 = 0;
                            let drAmount1 = 0;
                            respOne.data.forEach(item => {
                                if (item.transactionMode == 1) {
                                    crAmount1 += parseFloat(item.amount);
                                } else if (item.transactionMode == 2) {
                                    drAmount1 += parseFloat(item.amount);
                                }
                            });
                            setCrAmount(isNaN(crAmount1) == true ? 0 : crAmount1)
                            setDrAmount(isNaN(drAmount1) == true ? 0 : drAmount1)
                        }
                        else {
                            setCrAmount(0)
                            setDrAmount(0)
                        }

                        const SelectedAccount = respOne.data.accountCode1;
                        const SelectedAccount2 = respOne.data.accountCode2;

                        axios.get(`${BaseURLAccountMaster}?sid=${authUser.deptId}&acyr=${authUser.sessionYear}&bid=${authUser.branchId}`)
                            .then((respTwo) => {
                                setAccountData((respTwo.data).sort((a, b) => a.accountCode > b.accountCode ? 1 : -1));
                                setAccountDataTo((respTwo.data).sort((a, b) => a.accountCode > b.accountCode ? 1 : -1))

                                setAccountCodeObj(respTwo.data.find(e => e.accountCode == SelectedAccount));
                                setAccountCodeObj2(respTwo.data.find(e => e.accountCode == SelectedAccount2));

                                setAccountCode(SelectedAccount);
                                setAccountCode2(SelectedAccount2);
                                setLoaderOption(false);
                            })

                        axios.get(`${BaseURLNarrationMaster}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                            .then((respThree) => {
                                setNarrationData(respThree.data);
                                setLoaderOption(false);
                            })

                        axios.get(`${getDualSideClosingBalanceAPI}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                            .then(res => {
                                setClosingAmt(res.data);
                                setLoaderOption(false);
                            })

                    }
                    else {
                        setLoaderOption(false);
                        toast.warn("Voucher Number does not exist.")
                    }
                }
                else if (typeof respOne.data === 'object' && respOne.data !== null) {
                    if (respOne.data.voucherType == 1 || respOne.data.voucherType == 2) {
                        if (respOne.data.voucherId == voucherNo) {
                            setFormType(respOne.data.voucherType);
                            setVoucherId(respOne.data.voucherId);
                            setDateValue(moment(respOne.data.voucherDate).format("DD/MM/YYYY"));
                            //console.log("Date=====" + respOne.data[0].voucherId)
                            setNarration(respOne.data.narration);
                            setTransactionMode(respOne.data.transactionMode);
                            setAmount(respOne.data.transactionMode == 1 ? respOne.data.creditAmount : respOne.data.debitAmount)
                            setIsReceipt(respOne.data.isReceipt);
                            setIsNarrationSave(respOne.data.isNarrationSave);
                            const SelectedAccount = respOne.data.accountCode1;
                            const SelectedAccount2 = respOne.data.accountCode2;


                            //console.log(SelectedAccount)
                            axios.get(`${BaseURLAccountMaster}?sid=${authUser.deptId}&acyr=${authUser.sessionYear}&bid=${authUser.branchId}`)
                                .then((respTwo) => {
                                    setAccountData((respTwo.data).sort((a, b) => a.accountCode > b.accountCode ? 1 : -1));
                                    setAccountDataTo((respTwo.data).sort((a, b) => a.accountCode > b.accountCode ? 1 : -1))

                                    setAccountCodeObj(respTwo.data.find(e => e.accountCode == SelectedAccount));
                                    setAccountCodeObj2(respTwo.data.find(e => e.accountCode == SelectedAccount2));

                                    setAccountCode(SelectedAccount);
                                    setAccountCode2(SelectedAccount2);
                                    setLoaderOption(false);
                                })

                            axios.get(`${BaseURLNarrationMaster}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                                .then((respThree) => {
                                    setNarrationData(respThree.data);
                                    setLoaderOption(false);
                                })

                            axios.get(`${getDualSideClosingBalanceAPI}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                                .then(res => {
                                    setClosingAmt(res.data);
                                    setLoaderOption(false);
                                })


                            axios.get(`${getDualSideAccountClosingBalanceAPI}?accCode=${SelectedAccount}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                                .then(res => {
                                    //console.log(res.data);
                                    setAccClosingAmt(parseFloat(res.data.closing).toFixed('2'));
                                    setAccClosingFlag(res.data.flag)
                                    setLoaderOption(false);
                                })
                        }
                    } else {
                        setLoaderOption(false);
                        toast.warn("Voucher Number does not exist.")
                    }
                }
                else {
                    setLoaderOption(false);
                    toast.warn("Voucher Number is not exists.")
                }
            })
    }


    const getVoucherFormData = async (e) => {
        e.preventDefault();
        setHideBtn(false);
        // console.log(disableEditFlag + "=======" + disableEditFlag[5])
        if (disableEditFlag && disableEditFlag[5] == 1) {
            getDualVoucherEntry();
        }
        else {
            getSingleVoucherEntry();
        }

    }

    const deleteVoucherFunc = async () => {
        setLoaderOption(true);
        if (disableEditFlag && disableEditFlag[5] == 1) {
            await axios.delete(`${deleteDualSideVoucherAPIURL}?vno=${voucherNo}&sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
                .then((response) => {
                    if (response.data == "DELETED") {
                        clearData();
                        setLoaderOption(false);
                        toast.success("Voucher Entry Deleted Sucessfully.");
                    }
                });
        }
        else {
            await axios.delete(`${deleteSingleSideVoucherAPIURL}?vno=${voucherNo}&sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
                .then((response) => {
                    if (response.data == "DELETED") {
                        clearData();
                        setLoaderOption(false);
                        toast.success("Voucher Entry Deleted Sucessfully.");
                    }
                });
        }
    }

    const clearData = () => {
        setVoucherNo('');
        setClosingAmt(0);
        setNarration('');
        setAccountCodeObj(null);
        setAmount("");
        setIsReceipt(2);
        setAccountCode(null);
        setIsNarrationSave(0);
        setTransactionMode(1);
        setAccClosingAmt('');
        setAccClosingFlag('');
        setDateValue("");
        setHideBtn(true);
        setFormType(null);
    }

    const textFieldStyle = {
        color: closingAmt < 0 ? "#EA2C02" : "#0B9202",
        fontWeight: "550",
    };

    const textFieldStyle1 = {
        color: accClosingFlag == 2 ? "#EA2C02" : "#0B9202",
        fontWeight: "550",
    };


    return (

        (loaderOption === true) ?
            <Loader />
            :
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                    <form onSubmit={getVoucherFormData}>
                        <div className="row">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Voucher No :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    value={voucherNo}
                                    type="number"
                                    onChange={(e) => {
                                        setVoucherNo(e.target.value);
                                        setClosingAmt(0);
                                        setNarration('');
                                        setAccountCodeObj(null);
                                        setAmount("");
                                        setIsReceipt(2);
                                        setAccountCode(null);
                                        setIsNarrationSave(0);
                                        setTransactionMode(1);
                                        setAccClosingAmt('');
                                        setAccClosingFlag('');
                                        setDateValue("");
                                        setFormType(null);
                                        setHideBtn(true);
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Voucher No"
                                    onWheel={(e) => e.target.blur()}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 10 }}

                                />

                            </div>
                            <div className='col-sm-2'>
                                <button type='submit' style={{ marginTop: "8px" }} className='btn btn-primary btn-sm'>
                                    <SearchIcon fontSize='small' style={{ marginLeft: "-6px", fontWeight: 600 }} /> SEARCH
                                </button>
                            </div>
                        </div>
                        <hr />

                        {(() => {
                            if (disableEditFlag && disableEditFlag[5] == 1) {
                                return (
                                    <>
                                        <div className="row my-3">
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Voucher Type :</label>
                                            <div className="col-sm-3">
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel size="small" id="demo-simple-select-label" style={{ fontSize: '14px', marginBottom: "50px" }}>Voucher Type</InputLabel>
                                                    <Select
                                                        MenuProps={{ classes: { paper: classes.select } }}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={formType}
                                                        label="Voucher Type"
                                                        onChange={(e) => setFormType(e.target.value)}
                                                        size='small'
                                                        inputProps={{ readOnly: true }}
                                                    >
                                                        <MenuItem value={1}>Cash</MenuItem>
                                                        <MenuItem value={2}>Bank</MenuItem>
                                                        <MenuItem value={3}>JV Entry</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })()}


                        <div className="row" hidden={(formType == 3) ? true : false}>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: '16px' }} >Closing Amount :</label>
                            {/* <div className="" style={{ width: '120px' }}> */}
                            {/* <p className='' style={{ color: (closingAmt < 0) ? "#EA2C02" : "#0B9202", borderRadius: "3px", fontWeight: "620", fontSize: "15px", margin: "4px", padding: "5px", border: '1px solid #EBEDEF', lineHeight: "1.4", textAlign: 'center' }}>{(closingAmt < 0) ? parseFloat(-closingAmt).toFixed('2') : parseFloat(closingAmt).toFixed('2')}</p> */}
                            <div className='col-sm-4'>
                                <TextField
                                    // required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Closing Amount"
                                    variant="outlined"
                                    margin='dense'
                                    value={(closingAmt < 0) ? parseFloat(-closingAmt).toFixed('2') : parseFloat(closingAmt).toFixed('2')}
                                    //style={{ color: closingAmt < 0 ? "#EA2C02" : "#0B9202" }}
                                    // onChange={e => setVoucherId(e.target.value)}
                                    onKeyPress={(e) => (e.key === "Enter") ? closingAmt : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ readOnly: true }}
                                    InputProps={{
                                        style: textFieldStyle,
                                    }}
                                />

                            </div>
                            {/* </div> */}
                        </div>

                        <div className="row my-3" hidden={(formType == 3) ? true : false}>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >तारीख / Date :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    // required
                                    fullWidth
                                    type="text"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Acc. Closing Amount"
                                    variant="outlined"
                                    margin='dense'
                                    value={DateValue}
                                    //style={{ color: closingAmt < 0 ? "#EA2C02" : "#0B9202" }}
                                    // onChange={e => setVoucherId(e.target.value)}
                                    onKeyPress={(e) => (e.key === "Enter") ? DateValue : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ readOnly: true }}

                                />

                                {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                            </div>

                        </div>

                        <div className="row my-3" hidden={(formType == 3) ? true : false}>
                            <div className='offset-sm-2 col-sm-4'>
                                <FormControl>
                                    <RadioGroup row name="customized-radios" value={transactionMode}
                                        onKeyPress={(e) => (e.key == "Enter") ? transactionMode : ""}
                                        onChange={(e) => setTransactionMode(parseInt(e.target.value))}>
                                        {transactionModeData.map((item) => {
                                            return (
                                                <FormControlLabel onClick={(e) => e.preventDefault()} value={item.id} control={<Radio color='primary' />} label={item.Label} />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            {(() => {
                                if (transactionMode == 1) {
                                    return (
                                        <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>पावती / Receipt :</label>
                                    )
                                }
                                else if (transactionMode == 2) {
                                    return (
                                        <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>चलन / Payment :</label>
                                    )
                                }
                            })()}

                            <div className='col-sm-3'>
                                <RadioGroup
                                    row
                                    name="customized-radios"
                                    value={isReceipt}
                                    onKeyPress={(e) => (e.key == "Enter") ? isReceipt : ""}
                                    onChange={(e) => setIsReceipt(parseInt(e.target.value))}>
                                    <FormControlLabel onClick={(e) => e.preventDefault()} value={1} control={<Radio color='primary' />} label="हो / Yes" />
                                    <FormControlLabel onClick={(e) => e.preventDefault()} value={2} control={<Radio color='primary' />} label="नाही / No" />
                                </RadioGroup>
                            </div>
                        </div>

                        <div className="row my-3" hidden={(formType == 3) ? true : false}>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >खाते / Account :</label>
                            <div className='col-sm-4'>
                                <StyledAutocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    value={accountCodeObj}
                                    options={accountData}
                                    onChange={(event, newValue) => {
                                        setAccountCodeObj(newValue);
                                        //setAccClosingAmt('');
                                        setAccClosingFlag('');
                                        if (newValue != null) {
                                            setAccountCode(newValue.accountCode);
                                            //getAccClosingAmt(newValue.accountCode);
                                        }
                                    }}
                                    readOnly
                                    onKeyPress={(e) => (e.key == "Enter") ? accountCodeObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.accountCode + " - " + option.accountNameMr}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="खाते / Account" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                        // required
                                        />
                                    )}
                                />

                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >रक्कम / Amount :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    // required
                                    fullWidth
                                    type='number'
                                    onWheel={(e) => e.target.blur()}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="रक्कम / Amount"
                                    variant="outlined"
                                    margin='dense'
                                    value={amount}
                                    //step={0.00}                                  
                                    onChange={e => {
                                        setAmount(parseFloat(e.target.value));
                                        //setAmountCheck((parseFloat(e.target.value) > 0) ? false : true)

                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? amount : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120, readOnly: true }}

                                //InputProps={{ inputProps: { min: 1 } }}
                                />

                            </div>
                        </div>

                        {(() => {
                            if (disableEditFlag && disableEditFlag[5] == 1) {
                                if (formType == 1) {
                                    if (accountCodeObj != null) {
                                        return (
                                            <>
                                                <div className="row my-3" hidden={(formType == 3) ? true : false}>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: '16px' }} >Acc. Closing Amount :</label>
                                                    {/* <div className="col-sm-1">
                                                        <p className='' style={{ backgroundColor: (closingAmt < 0) ? "#FA6969" : "#1EA400", borderRadius: "4px", fontWeight: "600", fontSize: "14px", margin: "4px", padding: "5px", color: "#fff", lineHeight: "1.4", textAlign: 'center' }}>{(closingAmt < 0) ? parseFloat(-closingAmt).toFixed('2') : parseFloat(closingAmt).toFixed('2')}</p>
                                                    </div> */}
                                                    <div className='col-sm-4'>
                                                        <TextField
                                                            // required
                                                            fullWidth
                                                            type="number"
                                                            autoComplete='off'
                                                            size='small'
                                                            id="outlined-basic"
                                                            label="Acc. Closing Amount"
                                                            variant="outlined"
                                                            margin='dense'
                                                            value={accClosingAmt}
                                                            //style={{ color: closingAmt < 0 ? "#EA2C02" : "#0B9202" }}
                                                            // onChange={e => setVoucherId(e.target.value)}
                                                            onKeyPress={(e) => (e.key === "Enter") ? accClosingAmt : ""}
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            inputProps={{ readOnly: true }}
                                                            InputProps={{
                                                                style: textFieldStyle1,
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                }
                                else if (formType == 2) {
                                    return (
                                        <>
                                            <div className="row my-3" hidden={(formType == 3) ? true : false}>
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >खाते / Account To:</label>
                                                <div className='col-sm-4'>
                                                    <StyledAutocomplete
                                                        id="combo-box-demo"
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        value={accountCodeObj2}
                                                        options={accountDataTo}
                                                        onChange={(event, newValue) => {
                                                            setAccountCodeObj2(newValue);
                                                            if (newValue != null) {
                                                                setAccountCode2(newValue.accountCode);
                                                            }
                                                        }}
                                                        readOnly
                                                        onKeyPress={(e) => (e.key == "Enter") ? accountCodeObj2 : ""}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => option.accountCode + " - " + option.accountNameMr}
                                                        size="small"
                                                        renderInput={params => (
                                                            <TextField {...params} margin='dense' label="खाते / Account To" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                        )}
                                                    />
                                                </div>

                                            </div>
                                        </>
                                    )
                                }
                            }
                            else {
                                if (accountCodeObj != null) {
                                    return (
                                        <>
                                            <div className="row my-3" >
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: '16px' }} >Acc. Closing Amount :</label>
                                                {/* <div className="col-sm-1">
                                                    <p className='' style={{ backgroundColor: (closingAmt < 0) ? "#FA6969" : "#1EA400", borderRadius: "4px", fontWeight: "600", fontSize: "14px", margin: "4px", padding: "5px", color: "#fff", lineHeight: "1.4", textAlign: 'center' }}>{(closingAmt < 0) ? parseFloat(-closingAmt).toFixed('2') : parseFloat(closingAmt).toFixed('2')}</p>
                                                </div> */}
                                                <div className='col-sm-4'>
                                                    <TextField
                                                        // required
                                                        fullWidth
                                                        type="number"
                                                        autoComplete='off'
                                                        size='small'
                                                        id="outlined-basic"
                                                        label="Acc. Closing Amount"
                                                        variant="outlined"
                                                        margin='dense'
                                                        value={accClosingAmt}
                                                        //style={{ color: closingAmt < 0 ? "#EA2C02" : "#0B9202" }}
                                                        // onChange={e => setVoucherId(e.target.value)}
                                                        onKeyPress={(e) => (e.key === "Enter") ? accClosingAmt : ""}
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        inputProps={{ readOnly: true }}
                                                        InputProps={{
                                                            style: textFieldStyle1,
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        })()}

                        <div className="row my-3" hidden={(formType == 3) ? true : false}>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >तपशील / Narration :</label>
                            <div className='col-sm-10'>

                                <StyledAutocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    freeSolo
                                    disableClearable
                                    onKeyDown={(e) => (e.key === " " && narration.length === 0) ? e.preventDefault() : ""}
                                    options={(narration === "") ? [] : narrationData}
                                    value={narration}
                                    onChange={(event, newValue) => {
                                        //setNarrationId(newValue.id);
                                        //console.log(newValue.narrationDetail);
                                        setNarration(newValue.narrationDetail);
                                    }}
                                    readOnly
                                    inputValue={narration}
                                    onInputChange={(event, newInputValue) => {
                                        //setNarrationId(newInputValue.id);
                                        //console.log(newInputValue);
                                        setNarration(newInputValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? narration : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => {

                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            //console.log("option........"+JSON.stringify(option.inputValue))
                                            return option.narrationId + " . " + option.narrationDetail;
                                        }
                                        // Regular option
                                        return option.narrationId + " . " + option.narrationDetail;
                                    }

                                    }
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} multiline rows={5} margin='dense' label="तपशील / Narration" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                        // required
                                        />
                                    )}
                                />

                            </div>
                        </div>

                        {/* <div className="row mt-3" hidden={(formType == 3) ? true : false}>
                            <div className='col-sm-12 offset-sm-2'>
                                <FormControlLabel onClick={(e) => e.preventDefault()} control={<Checkbox checked={isNarrationSave} onChange={HandleNarrationCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Save Narration To Master" />
                            </div>
                        </div> */}

                        {(() => {
                            if (disableEditFlag && disableEditFlag[5] == 1) {
                                if (formType == 3) {
                                    return (
                                        <>

                                            <div className='table-responsive mt-3'>
                                                <table className='table table-md table-bordered'>
                                                    <thead>
                                                        <tr style={{ backgroundColor: '#EAECEE' }}>
                                                            <th style={{ fontSize: "13px", fontWeight: "700", margin: "6px" }}>Sr.No</th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700", margin: "6px" }}>V Date | तारीख</th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700", margin: "6px" }}>Account | खाते</th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700", margin: "6px" }}>Narration | तपशील</th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700", margin: "6px", lineHeight: '1.4' }}>Cr. Amt जमा रक्कम</th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700", margin: "6px", lineHeight: '1.4' }}>Dr. Amt नावे रक्कम</th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700", margin: "6px" }}>RC</th>
                                                            {/* <th style={{ fontSize: "13px", fontWeight: "700", margin: "6px" }}>Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            voucherDataMaster.length > 0
                                                                ?
                                                                accountData.length > 0 ?
                                                                    voucherDataMaster
                                                                        .map((item, index) => {
                                                                            return (

                                                                                <tr key={index}>
                                                                                    <td style={{ fontSize: "12px", fontWeight: "500", margin: "5px" }}>{parseInt(index) + 1}</td>
                                                                                    <td style={{ fontSize: "12px", fontWeight: "500", margin: "5px" }}>{item.voucherDate}</td>


                                                                                    <td style={{ fontSize: "12px", fontWeight: "500", margin: "5px", lineHeight: "1.6" }}>
                                                                                        {item.accountCode1 + " - " + accountData.find((item1) => item1.accountCode == item.accountCode1).accountNameMr}
                                                                                    </td>


                                                                                    <td style={{ fontSize: "12px", fontWeight: "500", margin: "5px", lineHeight: "1.6" }}>{item.narration}</td>
                                                                                    <td style={{ fontSize: "12px", fontWeight: "500", margin: "5px", textAlign: "right", width: "100px" }}>{(item.transactionMode == 1) ? parseFloat(item.amount).toFixed(2) : " "}</td>
                                                                                    <td style={{ fontSize: "12px", fontWeight: "500", margin: "5px", textAlign: "right", width: "100px" }}>{(item.transactionMode == 2) ? parseFloat(item.amount).toFixed(2) : " "}</td>

                                                                                    <td>
                                                                                        {(() => {
                                                                                            if (item.transactionMode == 1 && item.isReceipt == 1) {
                                                                                                return (
                                                                                                    <img src={ReceiptImage} alt="Receipt Image" title="Receipt" style={{ borderRadius: "0", height: "auto", width: "30px" }} />
                                                                                                )
                                                                                            } else if (item.transactionMode == 2 && item.isReceipt == 1) {
                                                                                                return (
                                                                                                    <img src={ChalanImage} alt="Chalan Image" title="Chalan" style={{ borderRadius: "0", height: "auto", width: "30px" }} />
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    ""
                                                                                                )
                                                                                            }
                                                                                        })()}
                                                                                    </td>
                                                                                    {/* <td style={{ fontSize: "12px", fontWeight: "500", margin: "5px" }}>
                                                                                        <DeleteIcon style={{ color: "red", fontSize: "22px", cursor: "pointer" }}
                                                                                            onClick={() => {
                                                                                                const updatedList = [...voucherDataMaster];
                                                                                                updatedList.splice(index, 1);
                                                                                                setVoucherDataMaster([].concat(updatedList));
                                                                                            }}
                                                                                        />
                                                                                    </td> */}
                                                                                </tr >

                                                                            )
                                                                        })
                                                                    : ""

                                                                : ""
                                                        }

                                                        <tr>
                                                            <th style={{ fontSize: "13px", fontWeight: "700" }}></th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700" }}></th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700" }}></th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700" }}>Total</th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700" }}>{parseFloat(crAmount).toFixed(2)}</th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700" }}>{parseFloat(drAmount).toFixed(2)}</th>
                                                            <th style={{ fontSize: "13px", fontWeight: "700" }}></th>
                                                            {/* <th style={{ fontSize: "13px", fontWeight: "700" }}></th> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        })()}



                        <div className="row mt-2">
                            <div className={(formType == 3) ? "col-sm-4" : "col-sm-4 offset-sm-2"}>
                                <button type='button' disabled={(hideBtn == true) ? true : false} className='btn btn-sm mr-2' style={{ backgroundColor: '#2E711C', color: '#fff' }} data-bs-toggle="modal" data-bs-target="#exampleModal">Delete Voucher</button>
                                <button type='button' className='btn btn-sm btn-danger' onClick={() => clearData()}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
                <CancelVoucherModalBox DeleteData={deleteVoucherFunc} />
            </>

    )
}

export default CancelVoucher
