import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import YearDropdown from '../../CommonComponent/LocalJSON/YearDropdown'
import { getClassDivisionURL, getStudentDailyAttendaceAPIURL, saveStudentDailyAttendaceAPIURL, studentAttendanceGetMapping, studentAttendancePostMapping } from '../Services/StudentAttendanceAPIURL';
import StudentAttendanceTextBox from './StudentAttendanceTableRow/StudentAttendanceTextBox';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import StudentDailyAttendaceInput from './StudentAttendanceTableRow/StudentDailyAttendaceInput';
import StudentDailyAttendaceModal from './StudentAttendanceTableRow/StudentDailyAttendaceModal';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const genderLabel = [
    {
        id: 1,
        gender: "Male"
    },
    {
        id: 2,
        gender: "Female"
    }
]

const presentyStatusData = [
    {
        id: 0,
        name: "Absent"
    },
    {
        id: 1,
        name: "Present"
    }
]


const StudentDailyAttendance = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Daily Attendance";
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [
        { label: 'Reg No', key: 'regCode' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Gender`, key: 'genderLabel' },
        { label: `Roll No`, key: 'rollNo' },
        { label: 'Presenty Status', key: 'presentyStatus' }];

    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [studAttendanceData, setStudAttendanceData] = useState([]);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [filteredData, setFilteredData] = useState([]);
    const [monthId, setMonthId] = useState('');
    const [classId, setClassId] = useState(null);
    const [divisionId, setDivisionId] = useState(null);

    const [id, setId] = useState("");
    const [tempdata, setTempData] = useState([]);
    const [regNum, setRegNum] = useState('');
    const [presentValue, setPresentValue] = useState('');

    const [fromClassName, setFromClassName] = useState("");
    const [modal, setModal] = useState(false);

    const [date, setDate] = useState(new Date())

    //console.log("filteredData=======" + JSON.stringify(filteredData));
    //console.log("presentDays=======" + presentDays);

    useEffect(() => {
        if (authUser.deptId != null || authUser.branchId != null) {
            getData();
        }
    }, [authUser]);

    const getData = async () => {
        //console.log(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setDivisionData(res.data);
            })
    }

    const getAttendanceData = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setFilteredData([]);
        setStudAttendanceData([]);
        setTempData([]);
        setSearchTerm("");
        if (moment(date).format("DD/MM/YYYY") != "Invalid date") {
            console.log(`${getStudentDailyAttendaceAPIURL}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classId}&did=${divisionId}&date=${moment(date).format("YYYY-MM-DD")}`);
            await axios(`${getStudentDailyAttendaceAPIURL}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classId}&did=${divisionId}&date=${moment(date).format("YYYY-MM-DD")}`)
                .then(res => {
                    if ((res.data).length == 0) {
                        setLoaderOption(false);
                        toast.warn("Student is not allocated to this class-division.")
                    } else {
                        setStudAttendanceData(res.data);
                        setFilteredData(res.data);
                        setTempData(res.data);
                        setLoaderOption(false);
                    }
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper Date, Please check.")
        }
    }

    useEffect(() => {

        let updateData = tempdata
        tempdata.map((e, index) => {
            if (e.regCode == regNum) {
                updateData[index] = ({ ...e, flag: presentValue })
            }
        })
        setStudAttendanceData(updateData);

        if (searchTerm == "") {
            setFilteredData(updateData);
        }

    }, [searchTerm, regNum])

    const confirmBoxSaveBtn = e => {
        e.preventDefault();
        setModal(true);
        setFromClassName((authUser.branchMedium == 1) ? classDivObj.classNameMr + " " + classDivObj.divisionNameMr : classDivObj.classNameEn + " " + classDivObj.divisionNameEn)
    }


    const saveStudAttendance = async (e) => {
        setLoaderOption(true);
        // setModal(true);
        // e.preventDefault();
        const saveObj = studAttendanceData.map(e => (
            {
                "regCode": e.regCode,
                "flag": parseInt(e.flag),
                "date": e.date,
                "classId": e.classId,
                "divisionId": e.divisionId,
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            }
        ))
        console.log(JSON.stringify(saveObj))

        await axios.post(`${saveStudentDailyAttendaceAPIURL}`, saveObj)
            .then((response) => {
                if (response.data == "SAVED") {

                    setFilteredData([]);
                    setStudAttendanceData([]);
                    setTempData([]);
                    setClassDivObj(null);
                    setDate(new Date());
                    setSearchTerm("");
                    setModal(false);
                    setLoaderOption(false);
                    toast.success("Student Attendance updation successfully done.");
                }
            })
            .catch(error => {
                console.log("Update Fire Query: " + error)
            })
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...studAttendanceData.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...studAttendanceData.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
        setFilteredData(studAttendanceData);
        setStudAttendanceData(studAttendanceData);
        getSortedData(studAttendanceData, key, isAction);
    }

    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentDailyAttendaceAPIURL}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classId}&did=${divisionId}&date=${moment(date).format("YYYY-MM-DD")}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === studAttendanceData.length && list.every((o, i) => Object.keys(o).length === Object.keys(studAttendanceData[i]).length && Object.keys(o).every(k => o[k] === studAttendanceData[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(studAttendanceData);
                }
            })
    }

    // Utility function to map id to name
    const mapIdToName = (id, dataArray) => {
        const item = dataArray.find(element => element.id === id);
        return item ? item.name || item.gender : '';
    };

    // Effect for filtering data
    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = studAttendanceData.filter((item) => {
            const genderName = mapIdToName(item.genderId, genderLabel); // Assuming your data has genderId
            const presentyStatusName = mapIdToName(item.flag, presentyStatusData); // Assuming your data has presentyStatusId

            return (
                item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.flag.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                genderName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                presentyStatusName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        setFilteredData(tempFilteredData);
    }, [searchTerm]);

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (studAttendanceData == null || studAttendanceData == "" || studAttendanceData == []) {
                    event.preventDefault();
                    let btn = document.querySelector('.studDailyAttendanceBtn')
                    if (btn !== null) {
                        console.log('Enter is pressed!');
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }


    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <form onSubmit={getAttendanceData}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        <div className='row'>
                            <div className='col-sm-3'>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        value={date}
                                        // minDate={defaultMinDate}
                                        // maxDate={defaultMaxDate}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                            setFilteredData([]);
                                            setStudAttendanceData([]);
                                            //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? date : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                                {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}

                            </div>

                            <div className='col-sm-3'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={divisionData}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    value={classDivObj}
                                    onChange={(e, newValue) => {
                                        setClassDivObj(newValue);
                                        setClassId(newValue.classId);
                                        setDivisionId(newValue.divisionId);

                                        setFilteredData([]);
                                        setStudAttendanceData([]);
                                    }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} label="Select Class-Division" margin='dense' autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className="col-sm-4 mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">View</button>
                            </div>
                        </div>
                    </form>

                    {(studAttendanceData == null || studAttendanceData == "" || studAttendanceData == [])
                        ?
                        <h4 className='mt-5'>No Records, Select Month and Class-Division</h4>
                        :
                        <>

                            <div className="row">
                                <div className="offset-sm-7 col-sm-3 mt-3">
                                    <input
                                        type="text"
                                        placeholder='Search Here'
                                        style={{ width: "250px", display: "block", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        className='form-control form-control-sm'
                                        value={searchTerm}
                                        onChange={(e) => { setFilteredData([]); setSearchTerm(e.target.value); }}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-10 mt-2'>
                                <form onSubmit={confirmBoxSaveBtn}>
                                    <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                        <table className="table table-bordered ">
                                            <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData
                                                    .map((item, index) => {
                                                        return (

                                                            <tr key={index} style={{ height: "65px" }}>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.regCode}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>
                                                                    {
                                                                        genderLabel.map(e => {
                                                                            if (e.id == item.genderId) {
                                                                                return e.gender
                                                                            }
                                                                        })
                                                                    }
                                                                </td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.rollNo}</td>

                                                                <StudentDailyAttendaceInput presentyStatusData={presentyStatusData} initialText={item.flag}
                                                                    onChange={(value) => {
                                                                        let updatedData = filteredData;
                                                                        updatedData[index] = ({ ...item, flag: value })
                                                                        setFilteredData(updatedData);
                                                                        setStudAttendanceData(updatedData);
                                                                        setPresentValue(value);
                                                                        setRegNum(updatedData[index].regCode);
                                                                        //console.log(JSON.stringify(updatedData))
                                                                    }}
                                                                />


                                                            </tr>

                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='mt-4'>
                                        <button className='studDailyAttendanceBtn btn btn-primary btn-sm mb-2' type="submit"><AddIcon fontSize="small" />Save Changes</button>
                                    </div>

                                </form>
                            </div>
                        </>
                    }

                </div>
                <ToastContainer position="top-right" theme="colored" />
                <StudentDailyAttendaceModal setModal={setModal} modal={modal} fromClassName={fromClassName} confirmStudent={saveStudAttendance} date={moment(date).format("DD/MM/YYYY")} />
            </>
        )
    }

}



export default StudentDailyAttendance
