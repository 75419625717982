import { BaseURLMaster } from "../../../CommonServices/APIURL";


//http://192.168.29.102:8081/institute-sss/institute/sss/master/saveRegionToSchoolAllocation
export const saveRegionToSChoolAPI = BaseURLMaster + "saveRegionToSchoolAllocation";

//http://192.168.29.102:8081/institute-sss/institute/sss/master/updateRegionToSchoolAllocation
export const updateRegionToSChoolAPI = BaseURLMaster + "updateRegionToSchoolAllocation";

//http://192.168.29.102:8081/institute-sss/institute/sss/master/deleteRegionToSchoolAllocation?id=1
export const deleteRegionToSChoolAPI = BaseURLMaster + "deleteRegionToSchoolAllocation";

//http://192.168.29.102:8081/institute-sss/institute/sss/master/getRegionToSchoolAllocationList
export const getRegionToSChoolAPI = BaseURLMaster + "getRegionToSchoolAllocationList";
