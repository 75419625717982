import React, { useEffect, useState } from 'react';
import NavItem from './MenuItems/NavItem';
import DropdownNavItem from './MenuItems/DropdownNavItem';
import MegaMenuNavItem from './MenuItems/MegaMenuNavItem';
import TestingMenuId from './Service/TestingMenuId';
import { useSelector } from 'react-redux';


const TestMenu = ({ sendingMenu, refreshMenu, showModal, setShowModal, handleMenuClick, disableEditFlag }) => {
    const authUser = useSelector((state) => state.user.value);
    //console.log(disableEditFlag)

    const [newFilteredJson, setNewFilteredJson] = useState([]);
    const [newSendingMenuJson, setNewSendingMenuJson] = useState([]);

    useEffect(() => {
        getMenus();
    }, [authUser, sendingMenu, disableEditFlag]); // Remove disableEditFlag from dependencies to avoid re-render loop

    const getMenus = () => {
        let filteredJSON1, filteredJSON2;
        if (authUser.feeType === 2) {
            filteredJSON1 = filterMenu(sendingMenu, 316);
            filteredJSON2 = filterMenu(sendingMenu, 316);
        } else {
            filteredJSON1 = filterMenu(sendingMenu, 326);
            filteredJSON2 = filterMenu(sendingMenu, 326);
        }

        if (disableEditFlag && disableEditFlag[6] === 0) {
            filteredJSON1 = filterOutMenuItem(filteredJSON1, 621);
            filteredJSON2 = filterOutMenuItem(filteredJSON2, 621);
        }

        setNewFilteredJson(filteredJSON1);
        setNewSendingMenuJson(filteredJSON2);
    };

    const filterMenu = (menuList, subItemId) => {
        return menuList.map((menu) => {
            if (menu.MenuType === "megamenu") {
                const filteredMegaMenuDropDown = menu.MegaMenuDropDown.map((megaMenu) => {
                    const filteredMegaMenuSubItems = megaMenu.MegaMenuSubItems.filter(
                        (subItem) => subItem.id !== subItemId
                    );
                    return { ...megaMenu, MegaMenuSubItems: filteredMegaMenuSubItems };
                });
                return { ...menu, MegaMenuDropDown: filteredMegaMenuDropDown };
            }
            return menu;
        });
    };

    const filterOutMenuItem = (menuList, itemId) => {
        return menuList.filter((menu) => menu.id !== itemId);
    };

    return (
        <>
            <ul className="nav page-navigation" style={{ marginBottom: "6px" }}>
                {
                    ((authUser.branchId === 50 || authUser.sessionYear <= 2022) ? newSendingMenuJson : newFilteredJson).map((items, index) => {
                        if (items.MenuType === "normal") {
                            return (
                                <NavItem
                                    LinkIcon={items.LinkIcon}
                                    Title={items.Title}
                                    PathName={items.PathName}
                                    refreshMenu={refreshMenu}
                                    key={index}
                                    setShowModal={setShowModal}
                                    handleMenuClick={handleMenuClick}
                                />
                            );
                        } else if (items.MenuType === "dropdown") {
                            return (
                                <DropdownNavItem
                                    LinkIcon={items.LinkIcon}
                                    Title={items.Title}
                                    PathName={items.PathName}
                                    DropDownSubMenu={items.DropDownSubMenu}
                                    refreshMenu={refreshMenu}
                                    key={index}
                                    handleMenuClick={handleMenuClick}
                                />
                            );
                        } else if (items.MenuType === "megamenu") {
                            return (
                                <MegaMenuNavItem
                                    LinkIcon={items.LinkIcon}
                                    Title={items.Title}
                                    PathName={items.PathName}
                                    id={items.id}
                                    MegaMenuDropDown={items.MegaMenuDropDown}
                                    refreshMenu={refreshMenu}
                                    key={index}
                                    handleMenuClick={handleMenuClick}
                                />
                            );
                        }
                    })
                }
            </ul>
        </>
    );
};

export default TestMenu;