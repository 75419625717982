const DengiAccountJSON = [
    {
        id: 130,
        MenuType: "normal",
        PathName: "/Home/m130/",
        Title: "Fee Master",
        LinkIcon: "dashboard",
    },
    {
        id: 314,
        MenuType: "normal",
        PathName: "/Home/f314/0",
        Title: "Dengi Pavati",
        LinkIcon: "account_balance_wallet"
    },
    {
        id: 312,
        MenuType: "normal",
        PathName: "/Home/f312/",
        Title: "Dengi Summary",
        LinkIcon: "receipt_long"
    },
    {
        id: 313,
        MenuType: "normal",
        PathName: "/Home/f313/",
        Title: "Dengi Pohoch",
        LinkIcon: "receipt_long"
    },
    {
        id: 321,
        MenuType: "normal",
        PathName: "/Home/f321/",
        Title: "Dengi Pavati Report",
        LinkIcon: "receipt_long"
    },
];

export default DengiAccountJSON;