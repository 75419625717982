import { BaseURLMaster } from "../../../CommonServices/APIURL";


//http://192.168.29.102:8081/institute-sss/institute/sss/master/saveRegionMaster
export const saveRegionMasterAPI = BaseURLMaster + "saveRegionMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/master/updateRegionMaster
export const updateRegionMasterAPI = BaseURLMaster + "updateRegionMaster";

// /http://192.168.29.102:8081/institute-sss/institute/sss/master/getRegionMasterList
export const getRegionMasterAPI = BaseURLMaster + "getRegionMasterList";

//http://192.168.29.102:8081/institute-sss/institute/sss/master/deleteRegionMaster?id=3
export const deleteRegionMasterAPI = BaseURLMaster + "deleteRegionMaster";

