import React, { useState } from 'react';
import { useSelector } from "react-redux";

const StudentBloodGroupInput = ({ bloodGroupData, initialText, onChange }) => {
    const authUser = useSelector((state) => state.user.value);
    const [isEdit, setEdit] = useState(false);
    const [text, setText] = useState(initialText);
    const [bloodGroup, setBloodGroup] = useState(bloodGroupData.find((e) => e.id == text));

    return (
        isEdit ?
            <td style={{ width: '200px' }}>
                <select value={text}
                    onChange={(e) => { setText(e.target.value); setBloodGroup(bloodGroupData.find((a) => a.id == e.target.value)); onChange(e.target.value) }}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(e.target.value) : ""}
                    className='form-select form-select-sm'
                    style={{ width: "160px", fontSize: "14px" }}
                    onBlur={() => { setEdit(false) }}
                    autoFocus
                >
                    {bloodGroupData.map((e) => {
                        return (
                            <option value={e.id}>
                                {e.nameMr}
                            </option>
                        )
                    })}

                </select>
            </td>

            :
            <td style={{ width: '200px' }} onClick={() => setEdit(true)}>{bloodGroup.nameMr}</td>
    )
}


export default StudentBloodGroupInput
