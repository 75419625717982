import { BaseIP, BaseURL, dBaseIP, dBaseURL } from "../../CommonServices/APIURL";

// /localhost:8080/institute/sss/administration/studentImage/getAllStudentImageData?sid=3&bid=1
export const getAllStudentsImageDetails = BaseURL + "administration/studentImage/getAllStudentImageData";

// img
// regNo
// sid
// bid
//localhost:8080/institute/sss/administration/studentImage/saveStudentImage
export const saveStudentPhotoAPIURL = BaseURL + "administration/studentImage/saveStudentImage";

// /localhost:8080/institute/sss/administration/studentImage/deleteStudentImage?regNo=12707&sid=3&bid=1
export const deleteStudentImageAPIURL = BaseURL + "administration/studentImage/deleteStudentImage";

// /localhost:8080/institute/sss/administration/studentImage/getStudentImageDataByRegNo?regNo=12879&sid=3&bid=1
export const viewStudentImageAPIURL = BaseURL + "administration/studentImage/getStudentImageDataByRegNo";

// /apache-tomcat-9.0.58/webapps/ROOT/zData/std_img/
export const imagePath = BaseIP + "/zData/std_img/"

//http://192.168.195.102:8081/institute-sss/institute/sss/administration/reports/getEmployeeIdCardReportById?langId=1&empId=25&year=2024&sid=3&bid=1&pageType=4
export const printEmployeeIdCardReportAPIURL = BaseURL + "administration/reports/getEmployeeIdCardReportById";
