import { dBaseURLMaster, BaseURLMaster } from "../../CommonServices/APIURL";

//get list
//localhost:8080/institute/sss/master/getAllGuardianDetail?sid=3&bid=1
export const getGuardianDetailsAPIURL = BaseURLMaster + "getAllGuardianDetail";

//save
// localhost:8080/institute/sss/master/saveGuardianDetail
// {
//     "regNo": 123,
//     "guardianName": "guardianName",
//     "relation": "relation",
//     "contactNo": "9545643879",
//     "address": "address",
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveGuardianDetailsAPIURL = BaseURLMaster + "saveGuardianDetail"

//update
//localhost:8080/institute/sss/master/updateGuardianDetail
export const updateGuardianDetaislAPIURL = BaseURLMaster + "updateGuardianDetail";

//delete 
//localhost:8080/institute/sss/master/deleteGuardianDetail?id=1
export const deleteGuardianDetailsAPIURL = BaseURLMaster + "deleteGuardianDetail";


//get blodd group
//http://192.168.29.102:8081/institute-sss/institute/sss/master/getBloodGroupMasterList
export const getBloodGroupMasterAPIURL = BaseURLMaster + "getBloodGroupMasterList";