const TeacherAcademicsJSON = [
	{
		MenuType: "normal",
		PathName: "/Home/dashboard",
		Title: "Dashboard",
		LinkIcon: "home"
	},
	{
		MenuType: "megamenu",
		PathName: "#",
		Title: "Master",
		LinkIcon: "dashboard",
		MegaMenuDropDown: [
			{
				HeaddingName: "Masters",
				MegaMenuSubItems: [
					{
						id: 106,
						SubPath: "/Home/m001/6",
						SubName: "Manage Mother Tongue"
					},
					{
						id: 108,
						SubPath: "/Home/m001/8",
						SubName: "Manage Caste"
					},
					{
						id: 109,
						SubPath: "/Home/m003/9",
						SubName: "Manage Subcaste"
					},
					{
						id: 110,
						SubPath: "/Home/m001/10",
						SubName: "Nationality Master"
					},
					{
						id: 111,
						SubPath: "/Home/m002/11",
						SubName: "Manage Domicile"
					},
					{
						id: 112,
						SubPath: "/Home/m002/12",
						SubName: "Manage Religion"
					},
					{
						id: 114,
						SubPath: "/Home/m001/14",
						SubName: "Manage Concession"
					},
				]

			},
			{
				HeaddingName: " ",
				MegaMenuSubItems: [
					{
						id: 115,
						SubPath: "/Home/m001/15",
						SubName: "Manage Disability"
					},
					{
						id: 118,
						SubPath: "/Home/m001/18",
						SubName: "Manage Progress Conduct"
					},
					{
						id: 120,
						SubPath: "/Home/m002/20",
						SubName: "Manage Language"
					},
					{
						id: 121,
						SubPath: "/Home/m001/21",
						SubName: "Manage Leaving Remark"
					},
					{
						id: 122,
						SubPath: "/Home/m002/22",
						SubName: "Manage CWSN"
					},
					{
						id: 127,
						SubPath: "/Home/m002/127",
						SubName: "Minority Master"
					},
					{
						id: 132,
						SubPath: "/Home/m001/132",
						SubName: "City Master"
					},
					{
						id: 123,
						SubPath: "/Home/m001/123",
						SubName: "Award Master"
					},
				]

			},
			{
				HeaddingName: " ",
				MegaMenuSubItems: [
					{
						id: 133,
						SubPath: "/Home/m001/133",
						SubName: "Document Master"
					},
					{
						id: 134,
						SubPath: "/Home/m01/134",
						SubName: "Old School Master"
					},
					{
						id: 135,
						SubPath: "/Home/m01/135",
						SubName: "Bank Master"
					},
					{
						id: 136,
						SubPath: "/Home/m01/136",
						SubName: "Batch Master"
					},
					{
						id: 137,
						SubPath: "/Home/m137/",
						SubName: "Guardian Detail"
					},
					{
						id: 138,
						SubPath: "/Home/m138/",
						SubName: "External Exam"
					}
				]

			},
			{
				HeaddingName: "Master Reports",
				MegaMenuSubItems: [
					{
						id: 125,
						SubPath: "/Home/m125/",
						SubName: "Master Reports"
					},
					{
						id: 126,
						SubPath: "/Home/m126/",
						SubName: "Employee Reports"
					},

				]

			}


		]
	},
	{
		MenuType: "megamenu",
		PathName: "#",
		Title: "Administration",
		LinkIcon: "analytics",
		MegaMenuDropDown: [
			{
				HeaddingName: "Administration",
				MegaMenuSubItems: [
					{
						id: 201,
						SubPath: "/Home/ad01/0",
						SubName: "Student Entry"
					},
					{
						id: 202,
						SubPath: "/Home/ad202/",
						SubName: "Students Bank Details"
					},
					{
						id: 203,
						SubPath: "/Home/ad203/",
						SubName: "Students Mother Tongue"
					},
					{
						id: 204,
						SubPath: "/Home/ad204/",
						SubName: "Students Village"
					},
					{
						id: 205,
						SubPath: "/Home/ad205/",
						SubName: "Cast Category"
					},
					{
						id: 206,
						SubPath: "/Home/ad206/",
						SubName: "Students Minority"
					},
					{
						id: 209,
						SubPath: "/Home/ad209/",
						SubName: "Parent Phone"
					},

					{
						id: 208,
						SubPath: "/Home/ad208/",
						SubName: "Pramot / Shift Students"
					},
					{
						id: 229,
						SubPath: "/Home/ad229/",
						SubName: "Transfer Students"
					},
					{
						id: 232,
						SubPath: "/Home/ad232/",
						SubName: "Student Photo"
					},
					{
						id: 234,
						SubPath: "/Home/ad234/",
						SubName: "Parent Data"
					},
				]

			},
			{
				HeaddingName: "Saral Data Update",
				MegaMenuSubItems: [
					{
						id: 211,
						SubPath: "/Home/ad211/",
						SubName: "Student-CWSN"
					},
					{
						id: 212,
						SubPath: "/Home/ad212/",
						SubName: "Student Religion"
					},
					{
						id: 213,
						SubPath: "/Home/ad213/",
						SubName: "Student Aadhar"
					},
					{
						id: 214,
						SubPath: "/Home/ad214/",
						SubName: "Student Saral"
					},
					{
						id: 215,
						SubPath: "/Home/404/",
						SubName: "Student Medium"
					},
					{
						id: 216,
						SubPath: "/Home/ad216/",
						SubName: "Student Allowances"
					},
					{
						id: 217,
						SubPath: "/Home/ad217/",
						SubName: "Semi English Students"
					},
					{
						id: 228,
						SubPath: "/Home/ad228/",
						SubName: "BPL Students"
					},
					{
						id: 218,
						SubPath: "/Home/ad218/",
						SubName: "Students ADM Type"
					},
					{
						id: 235,
						SubPath: "/Home/ad235/",
						SubName: "Students National Code"
					},
					{
						id: 238,
						SubPath: "/Home/ad238/",
						SubName: "Students Blood Group"
					}
				]

			},
			{
				HeaddingName: "Report",
				MegaMenuSubItems: [
					{
						id: 219,
						SubPath: "/Home/ad219/",
						SubName: "General Register"
					},
					{
						id: 220,
						SubPath: "/Home/ad220/",
						SubName: "General Register Checklist"
					},

					{
						id: 231,
						SubPath: "/Home/ad231/",
						SubName: "Category Summary"
					},
					{
						id: 223,
						SubPath: "/Home/ad223/",
						SubName: "Categorywise Student List"
					},
					{
						id: 224,
						SubPath: "/Home/ad224/",
						SubName: "Minority List"
					},
					{
						id: 233,
						SubPath: "/Home/ad233/",
						SubName: "ID card Printing"
					},
					{
						id: 236,
						SubPath: "/Home/ad236/",
						SubName: "Student National Code Report"
					}
				]

			},
			{
				HeaddingName: "UDISE Report",
				MegaMenuSubItems: [
					{
						id: 225,
						SubPath: "/Home/ad225/",
						SubName: "School Statistics"
					},
					{
						id: 226,
						SubPath: "/Home/ad226/",
						SubName: "Student Age Statistics"
					},
					{
						id: 227,
						SubPath: "/Home/ad227/",
						SubName: "UDIES-E"
					}
				]

			},
		]
	},
	{
		MenuType: "megamenu",
		PathName: "#",
		Title: "Admission",
		LinkIcon: "group_add",
		MegaMenuDropDown: [
			{
				HeaddingName: "Admission",
				MegaMenuSubItems: [
					{
						id: 301,
						SubPath: "/Home/adm301/",
						SubName: "Admission Form Sale"
					},
					{
						id: 302,
						SubPath: "/Home/adm302/0",
						SubName: "Admission Form Receive"
					},
					{
						id: 322,
						SubPath: "/Home/adm322/",
						SubName: "Admission Entrance"
					},
					{
						id: 303,
						SubPath: "/Home/adm303/",
						SubName: "Admission Confirmation"
					},

					{
						id: 304,
						SubPath: "/Home/adm304/",
						SubName: "Admission Cancellation"
					},

				]

			},
			{
				HeaddingName: "Admission Report",
				MegaMenuSubItems: [
					{
						id: 305,
						SubPath: "/Home/adm305/",
						SubName: "Admission Form Sale"
					},
					{
						id: 306,
						SubPath: "/Home/adm306/",
						SubName: "Admission Form Received"
					},
					{
						id: 307,
						SubPath: "/Home/adm307/",
						SubName: "Admission Merit List"
					},
					{
						id: 308,
						SubPath: "/Home/404/",
						SubName: "Student Admission Details"
					},

				]

			},


		]
	},
	{
		MenuType: "megamenu",
		PathName: "#",
		Title: "Fee",
		LinkIcon: "account_balance_wallet",
		MegaMenuDropDown: [
			{
				HeaddingName: "Fee Management",
				MegaMenuSubItems: [

					{
						id: 310,
						SubPath: "/Home/f310/",
						SubName: "Fee Demand"
					},
					{
						id: 323,
						SubPath: "/Home/f323/",
						SubName: "Student Wise Fee Demand"
					},
					{
						id: 325,
						SubPath: "/Home/f325/",
						SubName: "EBC Fee Demand"
					},
					{
						id: 311,
						SubPath: "/Home/f311/0",
						SubName: "Fee Receipt"
					},
					{
						id: 314,
						SubPath: "/Home/f314/0",
						SubName: "Dengi Pavati"
					},
				]

			},
			{
				HeaddingName: "Fee - Report",
				MegaMenuSubItems: [
					{
						id: 318,
						SubPath: "/Home/f318/",
						SubName: "Fee Demand Summary"
					},
					{
						id: 316,
						SubPath: "/Home/f316/",
						SubName: "Fee Receipt Summary"
					},
					{
						id: 309,
						SubPath: "/Home/f309/",
						SubName: "Bulk Fee Receipt"
					},
					{
						id: 317,
						SubPath: "/Home/f317/",
						SubName: "Fee Due List"
					},
					{
						id: 326,
						SubPath: "/Home/f326/",
						SubName: "Fee Collection Summary"
					},
				]

			},
			{
				HeaddingName: " ",
				MegaMenuSubItems: [

					{
						id: 321,
						SubPath: "/Home/f321/",
						SubName: "Dengi Pavati Report"
					},
					{
						id: 312,
						SubPath: "/Home/f312/",
						SubName: "Dengi Summary "
					},
					{
						id: 319,
						SubPath: "/Home/f319/",
						SubName: "Yearly Fee Collection"
					},
					{
						id: 320,
						SubPath: "/Home/f320/",
						SubName: "Fee Receipt Summary Excel Export"
					}
				]
			}
		]
	},
	{
		MenuType: "megamenu",
		PathName: "#",
		Title: "Academic",
		LinkIcon: "school",
		MegaMenuDropDown: [
			{
				HeaddingName: "Academic",
				MegaMenuSubItems: [
					{
						id: 401,
						SubPath: "/Home/acd401/",
						SubName: "Roll No Assign"
					},
					{
						id: 402,
						SubPath: "/Home/acd402/",
						SubName: "Subject Allocation"
					},
					{
						id: 403,
						SubPath: "/Home/acd403/",
						SubName: "Class Teacher Allocation"
					},
					{
						id: 404,
						SubPath: "/Home/acd404/",
						SubName: "Class Wise Subject To Teacher"
					},
					{
						id: 424,
						SubPath: "/Home/acd424/",
						SubName: "Student Wise Subjects"
					},
					{
						id: 406,
						SubPath: "/Home/acd406/0",
						SubName: "Manage Time Slots"
					},
					{
						id: 405,
						SubPath: "/Home/acd405/",
						SubName: "Manage Time Table"
					},
					{
						id: 411,
						SubPath: "/Home/acd411/0",
						SubName: "Award Distribution"
					},
					{
						id: 429,
						SubPath: "/Home/acd429/",
						SubName: "Exam Time Table"
					},
					{
						id: 430,
						SubPath: "/Home/acd430/",
						SubName: "External Exam Allocation"
					},
				]

			},
			{
				HeaddingName: "Academic Reports",
				MegaMenuSubItems: [
					{
						id: 407,
						SubPath: "/Home/acd407/",
						SubName: "Subject Allocation List"
					},
					{
						id: 408,
						SubPath: "/Home/acd408/",
						SubName: "Class Teacher Allocation List"
					},
					{
						id: 423,
						SubPath: "/Home/acd423/",
						SubName: "Subject To Teacher Allocation List"
					},
					{
						id: 425,
						SubPath: "/Home/acd425/",
						SubName: "Student Wise Subjects Allocation List"
					},
					{
						id: 410,
						SubPath: "/Home/acd410/",
						SubName: "EBC Report"
					},
					{
						id: 409,
						SubPath: "/Home/acd409/",
						SubName: "EBC Summary"
					},
					{
						id: 412,
						SubPath: "/Home/acd412/",
						SubName: "Total Time Table"
					},
					{
						id: 413,
						SubPath: "/Home/acd413/",
						SubName: "Employee Time Table"
					},
					{
						id: 427,
						SubPath: "/Home/acd428/",
						SubName: "Teachers Lecture Count"
					},
					{
						id: 426,
						SubPath: "/Home/acd426/",
						SubName: "Current Student Age List"
					},
					{
						id: 431,
						SubPath: "/Home/acd431/",
						SubName: "External Exam Allocation List"
					}
				]

			},
			{
				HeaddingName: "Catlog",
				MegaMenuSubItems: [
					{
						id: 415,
						SubPath: "/Home/acd415/",
						SubName: "Monthly Working Days"
					},
					{
						id: 432,
						SubPath: "/Home/acd432/",
						SubName: "Students Daily Attendance"
					},
					{
						id: 414,
						SubPath: "/Home/acd414/",
						SubName: "Students Monthly Attendance"
					},

					{
						id: 416,
						SubPath: "/Home/acd416/",
						SubName: "Student Batch"
					},
					{
						id: 417,
						SubPath: "/Home/acd417/",
						SubName: "Lesson Plan"
					},
					{
						id: 418,
						SubPath: "/Home/acd418/",
						SubName: "Monthly Lessons"
					}
				]

			},
			{
				HeaddingName: "Catlog Report",
				MegaMenuSubItems: [
					
					{
						id: 419,
						SubPath: "/Home/acd419/",
						SubName: "Catlog Print"
					},
					{
						id: 420,
						SubPath: "/Home/acd420/",
						SubName: "Catlog Print End Of Month"
					},
					{
						id: 421,
						SubPath: "/Home/404/",
						SubName: "Catlog Print Subject"
					},
					{
						id: 422,
						SubPath: "/Home/acd422/",
						SubName: "Catlog Print All Std"
					},
					{
						id: 433,
						SubPath: "/Home/acd433/",
						SubName: "Student Daily Attendance Report"
					},
					{
						id: 434,
						SubPath: "/Home/acd434/",
						SubName: "Daily Attendance Summary"
					},
				]

			}

		]
	},
	{
		MenuType: "megamenu",
		PathName: "#",
		Title: "Exam",
		LinkIcon: "menu_book",
		MegaMenuDropDown: [

			{
				HeaddingName: "Pre Exam",
				MegaMenuSubItems: [
					{
						id: 503,
						SubPath: "/Home/ex503/",
						SubName: "Exam Marks Entry"
					},
					{
						id: 541,
						SubPath: "/Home/ex541/",
						SubName: "Grace Exam Marks Entry"
					},
					{
						id: 504,
						SubPath: "/Home/404/",
						SubName: "Student Evaluation Entry"
					},
					{
						id: 505,
						SubPath: "/Home/ex505/",
						SubName: "Student RTE Result"
					},
					{
						id: 506,
						SubPath: "/Home/ex506/",
						SubName: "Term Observation Master"
					},
					{
						id: 507,
						SubPath: "/Home/ex507/",
						SubName: "Subject Observation Master"
					},
					{
						id: 510,
						SubPath: "/Home/ex510/",
						SubName: "Internal Seat No"
					},
					{
						id: 536,
						SubPath: "/Home/ex536/",
						SubName: "Board Seat No"
					},

				]

			},
			{
				HeaddingName: "After Exam",
				MegaMenuSubItems: [
					// {
					// 	id: 511,
					// 	SubPath: "/Home/404/",
					// 	SubName: "Exam Attendance"
					// },
					{
						id: 512,
						SubPath: "/Home/404/",
						SubName: "Generate Rank Std"
					},
					{
						id: 513,
						SubPath: "/Home/404/",
						SubName: "Generate Rank Div"
					},
					// {
					// 	id: 514,
					// 	SubPath: "/Home/404/",
					// 	SubName: "Pass Fail"
					// },
					{
						id: 515,
						SubPath: "/Home/ex515/",
						SubName: "Result Sheet Message"
					},
					{
						id: 516,
						SubPath: "/Home/ex516/",
						SubName: "Progress Card Message"
					},
					{
						id: 517,
						SubPath: "/Home/ex517/",
						SubName: "Term Wise Health"
					},
					{
						id: 514,
						SubPath: "/Home/ex514/",
						SubName: "Term Wise Observation"
					},
					{
						id: 518,
						SubPath: "/Home/ex518/",
						SubName: "Subject Wise Observation"
					},
					{
						id: 539,
						SubPath: "/Home/ex539/",
						SubName: "Board Exam Mark Entry"
					}
				]

			},

			{
				HeaddingName: "Exam Report",
				MegaMenuSubItems: [
					{
						id: 530,
						SubPath: "/Home/ex530/",
						SubName: "Exam Allocation List"
					},
					{
						id: 531,
						SubPath: "/Home/ex531/",
						SubName: "Exam Mark Allocation List"
					},
					// {
					// 	id: 533,
					// 	SubPath: "/Home/404/",
					// 	SubName: "Exam Marks Entry List"
					// },
					{
						id: 532,
						SubPath: "/Home/ex532/",
						SubName: "Subject Marks Sheet"
					},
					{
						id: 519,
						SubPath: "/Home/ex519/",
						SubName: "Result Sheet"
					},
					{
						id: 520,
						SubPath: "/Home/ex520/",
						SubName: "Progress Card"
					},
					{
						id: 521,
						SubPath: "/Home/ex521/",
						SubName: "Prapatra A"
					},
					{
						id: 540,
						SubPath: "/Home/ex540/",
						SubName: "Prapatra B"
					},
					{
						id: 535,
						SubPath: "/Home/ex535/",
						SubName: "Internal Seat No List"
					},
					{
						id: 537,
						SubPath: "/Home/ex537/",
						SubName: "Board Seat No List"
					},
					{
						id: 538,
						SubPath: "/Home/ex538/",
						SubName: "Board Internal Assess. Mark Sheet"
					},
					{
						id: 545,
						SubPath: "/Home/ex545/",
						SubName: "Board Exam Mark Details"
					},
					{
						id: 546,
						SubPath: "/Home/ex546/",
						SubName: "Board Exam Mark Summary"
					},
					{
						id: 522,
						SubPath: "/Home/404/",
						SubName: "Freq Distribution"
					},
					{
						id: 523,
						SubPath: "/Home/404/",
						SubName: "Single Sub Examwise Progress Chart"
					},
					{
						id: 524,
						SubPath: "/Home/404/",
						SubName: "Cumulative Record"
					},
					{
						id: 542,
						SubPath: "/Home/ex543/",
						SubName: "Term Observation Report"
					},
					{
						id: 543,
						SubPath: "/Home/ex544/",
						SubName: "Subject Observation Report"
					}
				]

			},
			{
				HeaddingName: "Rank",
				MegaMenuSubItems: [
					{
						id: 525,
						SubPath: "/Home/r525/",
						SubName: "Rank"
					},
					// {
					// 	id: 526,
					// 	SubPath: "/Home/r526/",
					// 	SubName: "Rank Std"
					// },
					// {
					// 	id: 527,
					// 	SubPath: "/Home/404/",
					// 	SubName: "Merit List Selected Sub"
					// },
					{
						id: 528,
						SubPath: "/Home/r528/",
						SubName: "Class Merit List"
					},
					{
						id: 529,
						SubPath: "/Home/r529/",
						SubName: "Subject Merit List"
					}
				]

			}
		]
	}
];

export default TeacherAcademicsJSON;