import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import GroupsIcon from '@mui/icons-material/Groups';

const categoryData = [
    {
        schoolName: "abc",
        sc: "2",
        nt: "4",
        obc: "5",
        total: "11"
    },
    {
        schoolName: "pqr",
        sc: "5",
        nt: "3",
        obc: "2",
        total: "10"

    },
    {
        schoolName: "lmn",
        sc: "1",
        nt: "0",
        obc: "5",
        total: "6"

    }
]

const SansthaDashboard = () => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    return (
        <>
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                <div className="row">
                    <div className="col-sm-6">
                        <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "250px", marginBottom: "30px" }}>
                            {/* Header */}
                            <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                                <span><CategoryIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                                <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Category Summary</span>
                            </div>
                            {/* Body */}
                            <div className='bodyWrapper' style={{ maxHeight: "180px", overflowY: "scroll", margin: "4px 0px" }}>
                                <div>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9", }}>
                                                <tr>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Sr No.</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>School Name</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>SC</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>NT</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>OBC</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    categoryData.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{index + 1}</td>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.schoolName}</td>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.sc}</td>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.nt}</td>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.obc}</td>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.total}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "250px", marginBottom: "30px" }}>
                            {/* Header */}
                            <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                                <span><GroupsIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                                <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Minority Summary</span>
                            </div>
                            {/* Body */}
                            <div className='bodyWrapper' style={{ maxHeight: "180px", overflowY: "scroll", margin: "4px 0px" }}>
                                <div>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9", }}>
                                                <tr>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Sr No.</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>School Name</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>SC</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>NT</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>OBC</th>
                                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total</th>
                                                </tr>
                                            </thead>
                                            {/* <tbody>
                                                {
                                                    registerTypeData.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{index + 1}</td>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.registerTypeName}</td>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{(accessionData.filter(e => e.registerTypeName == item.registerTypeName)).length}</td>
                                                                <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody> */}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SansthaDashboard
