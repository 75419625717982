import { BaseURL } from "../../CommonServices/APIURL";


//POST  http://192.168.29.102:8081/institute-sss/institute/sss/payroll/employee/saveOfficeWorkInOut
export const saveOfficeWorkInOutAPIURL = BaseURL + "payroll/employee/saveOfficeWorkInOut";

// / http://192.168.29.102:8081/institute-sss/institute/sss/payroll/employee/updateOfficeWorkInOut
export const updateOfficeWorkInOutAPIURL = BaseURL + "payroll/employee/updateOfficeWorkInOut";

//http://192.168.29.102:8081/institute-sss/institute/sss/payroll/employee/getOfficeWorkInOutList?sid=3&bid=1
export const getOfficeWorkInOutAPIURL = BaseURL + "payroll/employee/getOfficeWorkInOutList";

// http://192.168.29.102:8081/institute-sss/institute/sss/payroll/employee/deleteOfficeWorkInOut?id=1
export const deleteOfficeWorkInOutAPIURL = BaseURL + "payroll/employee/deleteOfficeWorkInOut";

