import React, { useState, useEffect } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import axios from 'axios';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { getStudentRegistrationDataAPIURL, setStudentDataBySchoolRegistration, updateRegistarationAPIURL, updateStatusForStudRegistration } from '../Services/MobileAppAllAPI';
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';
import { ClassMasterGetMapping } from '../../CommonServices/ClassMasterAPIURL';
import { Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import Badge from 'react-bootstrap/Badge';

const useStyles = makeStyles({
  caption: {
    padding: 0,
    fontWeight: 600,
    fontSize: "12px"
  },
  toolbar: {
    fontSize: "12px",
    fontWeight: 600
  },
  selectLabel: {
    fontSize: "12px",
    fontWeight: 500,
    margin: "-10px",
    padding: "0"
  },
  select: {
    fontSize: "12px",
    margin: "0",
    padding: "0"
  },
  displayedRows: {
    fontSize: "12px",
    margin: "-22px",
    padding: "0"
  },
  menuItem: {
    fontSize: "12px",
    fontWeight: 500,
  },
  select: {
    maxHeight: 200,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  },
});


const RegistrationApproval = () => {
  const titleId = "Student Registration Approval"
  const classes = useStyles();
  const authUser = useSelector((state) => state.user.value);
  const [isAscending, setAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState(null);
  const [searchTerm, setSearchTerm] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [loaderOption, setLoaderOption] = useState(false);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [studName, setStudName] = useState('');
  const [regNo, setRegNo] = useState('');
  const [currentClass, setCurrentClass] = useState('');
  const [regStatus, setRegStatus] = useState('');
  const [classData, setClassData] = useState([]);
  const [getMsg, setGetMsg] = useState(false);
  const [id, setId] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [count, setCount] = useState('');

  const pendingData = data.filter(e => {
    return e.status == 3
  })

  const approvedData = data.filter(e => {
    return e.status == 1
  })

  const BlockedData = data.filter(e => {
    return e.status == 2
  })

  useEffect(() => {
    getData();
  }, [authUser])

  const getData = async () => {
    if (authUser.branchId != null) {
      await axios.get(`${getStudentRegistrationDataAPIURL}?bid=${authUser.branchId}`)
        .then(res => {
          setData(res.data);
          setFilteredData(res.data);
        })
    }

    await axios.get(`${ClassMasterGetMapping}`)
      .then(res => {
        setClassData(res.data);
      })
  }

  const onPageChange = (event, nextPage) => {
    setPage(nextPage);
  }

  const onRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  }

  const onSort = (key, isAction) => {
    if (!isAction) {
      setSortedBy(key)
      if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
      else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
      setAscending(!isAscending)
    }
  }

  const TableHeading = [
    { label: 'Sr No', key: 'id' },
    { label: `User Name`, key: 'name' },
    { label: `Reg No`, key: 'regId' },
    { label: `Class`, key: 'classId' },
    { label: `Mobile`, key: 'mobileNo' },
    { label: `Email Id`, key: 'emailId' },
    { label: 'Status', value: 'status', isAction: true },
    // { label: 'View', value: 'view', isAction: true }
  ];

  const getFilteredData = (value) => {
    setLoaderOption(true);
    setFilteredData([]);
    setAscending(false);
    setSortedBy(null);
    // setSearchTerm("");
    setPage(0);
    if (value == 1) {
      setFilteredData(pendingData);
      setLoaderOption(false);
    }
    else if (value == 2) {
      setFilteredData(approvedData);
      setLoaderOption(false);
    }
    else if (value == 3) {
      setFilteredData(BlockedData);
      setLoaderOption(false);
    }
    else if (value == 4) {
      getData();
      setLoaderOption(false);
    }
  }

  useEffect(() => {
    setFilteredData([]);
    let tempFilteredData = data.filter(item => (
      (item.id || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.regId || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.mobileNo || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.emailId || '').toLowerCase().includes(searchTerm.toLowerCase())
    ))
    setFilteredData(tempFilteredData);
  }, [searchTerm])

  const handleCloseModal = () => {
    setShowModal(false);
    setStudName('');
    setRegNo('');
    setCurrentClass('');
    setRegStatus('');
  }

  const getStudentDetails = async (item) => {
    setShowModal(true);
    setLoaderOption(true);
    setStudName('');
    setCurrentClass('');
    setRegNo('');
    setRegStatus('');
    setGetMsg(false);
    console.log(`${setStudentDataBySchoolRegistration}?regNo=${item.regId}&sessionYear=${authUser.sessionYear}&cid=${item.classId}&bid=${authUser.branchId}`)
    await axios.get(`${setStudentDataBySchoolRegistration}?regNo=${item.regId}&sessionYear=${authUser.sessionYear}&cid=${item.classId}&bid=${authUser.branchId}`)
      .then(res => {
        console.log(res.data);
        if (res.data == "StudentNotExist") {
          setGetMsg(true);
          setRegStatus(item.status);
          setLoaderOption(false);
        }
        else {
          setId(item.id);
          setSectionId(res.data.sectionId);
          setStudName((authUser.branchMedium == 1) ? res.data.studentNameMr : res.data.studentNameEn)
          setRegNo(res.data.regCode);
          classData.map(e => {
            if (e.id == res.data.currentClassId) {
              setCurrentClass((authUser.branchMedium == 1) ? e.nameMr : e.nameEn)
            }
          })
          setRegStatus(item.status);
          setGetMsg(false);
          setLoaderOption(false);
        }
      }).catch(err => {
        console.log("Student Details error:" + err);
        setLoaderOption(false);
        toast.error("Something went wrong, please check");
      })
  }

  const updateStatus = async () => {
    setShowModal(false);
    setLoaderOption(true);
    await axios.put(`${updateStatusForStudRegistration}?id=${id}&status=${regStatus}&sid=${sectionId}`)
      .then(res => {
        console.log(res.data);
        if (res.data == "SAVED") {
          setLoaderOption(false);
          getData();
          toast.success("Status Updated Successfully.")
        }
      }).catch(err => {
        console.log("update status error:" + err);
        setLoaderOption(false);
        toast.error("Something went wrong, please check.")
      })
  }

  const updateRegistration = async () => {
    setLoaderOption(true);
    await axios.put(`${updateRegistarationAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        if (res.data.message == "SAVED") {
          console.log("Count===" + res.data.count)
          setCount(res.data.count);
          getData();
          setLoaderOption(false);
          toast.success("Registration Updated successfully.")
        }
        else {
          setLoaderOption(false);
          toast.error("Update registration failed.")
        }
      }).catch(err => {
        setLoaderOption(false);
        console.log("Update registration err" + err);
        toast.error("Something went wrong, please check.")
      })
  }

  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <>
            <div>
              <TitleLabel titleId={titleId} />
              <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "480px" }}>
                <div className="row align-items-center">
                  <div className="col-auto">
                    <button type='button' className='btn btn-sm btn-primary' onClick={() => updateRegistration()}>Update Registration</button>
                  </div>
                  {count !== '' && (
                    <div className="col-auto">
                      <p style={{ backgroundColor: "#FC2431", borderRadius: "4px", fontWeight: "600", fontSize: "13px", margin: "4px", padding: "4px", color: "#fff", lineHeight: "1.4" }}>
                        Data Inserted :
                        <span style={{ backgroundColor: "#fff", color: "#000", borderRadius: "6px", width: "auto", padding: "3px 8px", marginLeft: "5px", fontSize: "13px", fontWeight: "600", marginTop: "2px", display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
                          {count}
                        </span>
                      </p>
                    </div>

                  )}
                </div>

                <hr />
                <div className=''>
                  <div className="" style={{ float: "left" }}>
                    <button type="button" onClick={() => { getFilteredData(2) }} className="btn btn-sm mb-3" style={{ backgroundColor: "#198E0B", color: "#fff" }}>Approved
                      <span style={{ backgroundColor: "#fff", color: "#000", borderRadius: "6px", width: "auto", paddingRight: "3px", marginLeft: "5px", fontSize: "14px", fontWeight: "600", marginTop: "2px", textAlign: "center" }}>  {approvedData.length}</span>
                      {/* <Badge pill bg="light" style={{ color: "#000", marginLeft: "5px", fontSize: "12px", fontWeight: "600", marginTop: "2px", textAlign: "center" }}>  {approvedData.length}</Badge> */}
                    </button>
                    <button type="button" onClick={() => { getFilteredData(3) }} className="btn btn-sm ml-2 mb-3" style={{ backgroundColor: "#CD2913", color: "#fff" }}>Blocked
                      <span style={{ backgroundColor: "#fff", color: "#000", borderRadius: "6px", width: "auto", paddingRight: "3px", marginLeft: "5px", fontSize: "14px", fontWeight: "600", marginTop: "2px", textAlign: "center" }}>  {BlockedData.length}</span>
                      {/* <Badge pill bg="light" style={{ color: "#000", marginLeft: "5px", fontSize: "12px", fontWeight: "600", marginTop: "2px", textAlign: "center" }}>  {BlockedData.length}</Badge> */}
                    </button>
                    {/* <button type="button" onClick={() => { getFilteredData(1) }} className="btn btn-sm btn-primary ml-2 mb-3" style={{ backgroundColor: "", color: "#fff" }}>Pending
                      <span style={{ backgroundColor: "#fff", color: "#000", borderRadius: "6px", width: "auto", paddingRight: "3px", marginLeft: "5px", fontSize: "14px", fontWeight: "600", marginTop: "2px", textAlign: "center" }}>  {pendingData.length}</span> */}
                    {/* <Badge pill bg="light" style={{ color: "#000", marginLeft: "5px", fontSize: "12px", fontWeight: "600", marginTop: "2px", textAlign: "center" }}>  {pendingData.length}</Badge> */}
                    {/* </button> */}
                    <button type="button" onClick={() => { getFilteredData(4) }} className="btn btn-sm ml-2 mb-3" style={{ backgroundColor: "#681F92", color: "#fff" }}>Reset</button>
                  </div>
                  <div>
                    <input
                      className='form-control form-control-sm'
                      type="text"
                      onKeyDown={(e) => (e.key === " " && searchTerm.length === 0) ? e.preventDefault() : ""}
                      style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                      placeholder="Search Here"
                      value={searchTerm}
                      onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                    />
                  </div>
                </div>

                {/* <div className=""> */}
                <div className='table-responsive'>
                  <table className="table table-bordered">
                    <thead className="table-Default">
                      <tr>
                        {TableHeading.map((item, index) => {
                          return (
                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                      {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .reverse().map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>
                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.name}</td>
                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regId}</td>
                                <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                  {
                                    classData.map(e => {
                                      if (e.id == item.classId) {
                                        return (authUser.branchMedium == 1) ? e.nameMr : e.nameEn
                                      }
                                    })
                                  }
                                </td>
                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.mobileNo}</td>
                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.emailId}</td>
                                <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                  {(() => {
                                    if (item.status == 1) {
                                      return (
                                        <span className="badge" onClick={() => getStudentDetails(item)} style={{ backgroundColor: "#198E0B", cursor: "pointer", fontSize: "13px" }}>Approved</span>
                                      )
                                    }
                                    else if (item.status == 2) {
                                      return (
                                        <span className="badge" onClick={() => getStudentDetails(item)} style={{ backgroundColor: "#CD2913", cursor: "pointer", fontSize: "13px" }}>Blocked</span>
                                      )
                                    }
                                    else if (item.status == 3) {
                                      return (
                                        <span className="badge badge-primary" onClick={() => getStudentDetails(item)} style={{ backgroundColor: "", cursor: "pointer", fontSize: "13px" }}>Pending</span>
                                      )
                                    }
                                  })()}
                                </td>
                              </tr>
                            </React.Fragment>
                          )
                        })}
                    </tbody>
                    <tfoot style={{ padding: "0px" }}>
                      <tr>
                        <TablePagination
                          style={{ padding: "0px" }}
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          count={data.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onRowsPerPageChange={onRowsPerPageChange}
                          onPageChange={onPageChange}
                          labelRowsPerPage="Rows Per Page :"
                          classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                          backIconButtonProps={{ "aria-label": "Previous Page" }}
                          nextIconButtonProps={{ "aria-label": "Next Page" }} />
                      </tr>
                    </tfoot>
                  </table>
                </div>
                {/* </div> */}
              </div>
            </div>

            {(() => {
              if (getMsg == false) {
                return (
                  <>
                    {/* Modal For updating status */}
                    <Modal show={showModal} onHide={() => handleCloseModal()} size="sm">
                      <Form onSubmit={updateStatus}>
                        <Modal.Header closeButton>
                          <Modal.Title style={{ fontSize: "18px" }}>School Registered Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                            <Box>
                              <Form.Label style={{ width: "110px", fontSize: "15px", fontWeight: "600" }}>Name : </Form.Label>
                              <span style={{ color: "#045DB7", fontSize: "14px", fontWeight: "700" }}> {studName}</span>
                            </Box>

                            <Box>
                              <Form.Label style={{ width: "110px", fontSize: "15px", fontWeight: "600" }}>Reg No : </Form.Label>
                              <span style={{ color: "#045DB7", fontSize: "14px", fontWeight: "700" }}> {regNo}</span>
                            </Box>

                            <Box>
                              <Form.Label style={{ width: "110px", fontSize: "15px", fontWeight: "600" }}>Current Class : </Form.Label>
                              <span style={{ color: "#045DB7", fontSize: "14px", fontWeight: "700" }}> {currentClass}</span>
                            </Box>

                            {/* <Box sx={{ display: 'block' }}>                             
                              <TextField
                                type="text"
                                label="Name"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                value={studName}
                                onKeyPress={(e) => (e.key == "Enter") ? studName : ''}
                                onChange={(e) => setStudName(e.target.value)}
                                autoComplete="off"
                                //style={{ width: "300px" }}
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                              />
                            </Box>

                            </Form.Group>

                            <Form.Group className="mb-1" controlId="exampleForm.ControlInput2">
                            <Box sx={{ display: 'block' }}>                            
                              <TextField
                                type="number"
                                label="Reg No"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                value={regNo}
                                onKeyPress={(e) => (e.key == "Enter") ? regNo : ''}
                                onChange={(e) => setRegNo(e.target.value)}
                                autoComplete="off"
                                //style={{ width: "200px" }}
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}

                              />
                            </Box>
                          </Form.Group>

                            <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">
                            <Box sx={{ display: 'block' }}>                              
                              <TextField
                                type="text"
                                label="Current Class"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                value={currentClass}
                                onKeyPress={(e) => (e.key == "Enter") ? currentClass : ''}
                                onChange={(e) => setCurrentClass(e.target.value)}
                                autoComplete="off"
                                //style={{ width: "200px" }}
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}

                              />
                            </Box> */}
                          </Form.Group>

                          <hr></hr>

                          <Form.Group className="mt-2" controlId="exampleForm.ControlInput4">
                            <Box sx={{ display: 'block' }}>
                              {/* <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Status :</Form.Label> */}
                              <FormControl fullWidth
                                //style={{ width: "200px" }}
                                margin='dense'>
                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Status</InputLabel>
                                <Select
                                  MenuProps={{ classes: { paper: classes.select } }}
                                  size='small'
                                  readOnly={(getMsg == true) ? true : false}
                                  required
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={regStatus}
                                  label="Status"
                                  onChange={(e) => {
                                    setRegStatus(e.target.value);
                                  }}
                                >
                                  <MenuItem value={1}>Approve</MenuItem>
                                  <MenuItem value={2}>Block</MenuItem>
                                  <MenuItem value={3}>Pending</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" type='submit' className='registerApprovalModalBtn'>
                            Save
                          </Button>
                          <Button variant="danger" type='button' onClick={() => handleCloseModal()}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Form>
                    </Modal>
                  </>
                )
              }
              else if (getMsg == true) {
                return (
                  <>
                    <Modal show={showModal} onHide={() => handleCloseModal()} size="md">
                      <Modal.Header>
                        <Modal.Title>Reg no. does not match.</Modal.Title>
                      </Modal.Header>
                      <Modal.Footer>
                        <Button variant="danger" onClick={() => setShowModal(false)}>Ok</Button>
                        {/* <Button variant="danger" onClick={() => setShowModal(false)}>Close</Button> */}
                      </Modal.Footer>
                    </Modal>
                  </>
                )
              }
            })()}

          </>
      }
      <ToastContainer position="top-right" theme="colored" />
    </>
  )
}

export default RegistrationApproval