import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { CheckCircle, Cancel, ThumbDownAlt, ThumbUpAlt } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

const StudentDailyAttendaceInput = ({ presentyStatusData, initialText, onChange }) => {
    const authUser = useSelector((state) => state.user.value);
    const [isEdit, setEdit] = useState(false);
    const [text, setText] = useState(initialText);
    const [admissionTypeName, setAdmissionTypeName] = useState(
        presentyStatusData.find((e) => e.id == text)
    );

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        setText(selectedValue);
        const selectedType = presentyStatusData.find((a) => a.id == selectedValue);
        setAdmissionTypeName(selectedType);
        onChange(selectedValue);
    };

    return isEdit ? (
        <td style={{ width: '180px', textAlign: 'center' }}>
            <select
                value={text}
                onChange={handleSelectChange}
                onKeyPress={(e) => (e.key == "Enter" ? handleSelectChange(e) : "")}
                className='form-select form-select-sm'
                style={{ width: "160px", fontSize: "14px", fontWeight: "500" }}
                onBlur={() => { setEdit(false) }}
                autoFocus
            >
                {presentyStatusData.map((e) => (
                    <option key={e.id} value={e.id}>
                        {e.name}
                    </option>
                ))}
            </select>
        </td>
    ) : (
        <td
            style={{ width: '180px', fontSize: "14px", fontWeight: "500", textAlign: 'center', cursor: 'pointer' }}
            onClick={() => setEdit(true)}
        >
            {text == "0" ? (
                <Tooltip title="Absent" arrow>
                    <ThumbDownAlt style={{ color: 'red', verticalAlign: 'middle' }} />
                </Tooltip>
            ) : text == "1" ? (
                <Tooltip title="Present" arrow>
                    <ThumbUpAlt style={{ color: 'green', verticalAlign: 'middle' }} />
                </Tooltip>
            ) : (
                admissionTypeName.name
            )}
        </td>
    );
};

export default StudentDailyAttendaceInput;
