import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, Switch, TextField } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { BaseURLInstituteMaster, getUserRolesAPIURL } from '../../Sanstha/Services/SansthaAPIURL';
import axios from 'axios';
import { useSelector } from 'react-redux';
import FormProcessingTable from './FormProcessingTable';
import { getFormProcessingAPIURL, saveFormProcessingAPIURL, updateFormProcessingAPIURL } from '../Service/FormProcessingAPIURL';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const FormProcessing = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.service);

    const [loaderOption, setLoaderOption] = useState(false);
    const [id, setId] = useState('');
    const [flag, setFlag] = useState('');
    const [description, setDescription] = useState("");
    const [roleData, setRoleData] = useState([]);
    const [roleObj, setRoleObj] = useState([]);
    const [roleId, setRoleId] = useState([]);
    const [option, setOption] = useState(0);
    const [branchData, setBranchData] = useState([]);
    const [branchObj, setBranchObj] = useState([]);
    const [branchId, setBranchId] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Description `, key: 'description' },
        { label: 'Role', value: 'roleName' },
        { label: 'Branch', value: 'branchName' },
        { label: 'Flag', value: 'flag' },
        { label: 'Edit', value: 'edit', isAction: true },
    ];

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoaderOption(true);
        await axios.get(getUserRolesAPIURL)
            .then((response) => {
                if (response.data !== null) {
                    let newData = response.data;
                    // let updatedData = [{ "id": 0, "name": "All" }, ...newData]
                    setRoleData(newData);
                    setLoaderOption(false);
                    // console.log(JSON.stringify(newData))
                }
            })
            .catch((error) => {
                console.log(error);

            })

        console.log(BaseURLInstituteMaster)
        await axios.get(BaseURLInstituteMaster)
            .then((response) => {
                if (response.data !== null) {

                    let newData = response.data;
                    // let updatedData = [{ "id": 0, "schoolNameEn": "All", "schoolNameMr": "All" }, ...newData]
                    setBranchData(newData);
                    setLoaderOption(false);
                    //console.log(JSON.stringify(updatedData))
                }
            })
            .catch((error) => { console.log(error); })

        // console.log(getFormProcessingAPIURL)
        await axios.get(getFormProcessingAPIURL)
            .then((response) => {
                if (response.data !== null) {

                    let newData = response.data;
                    // let updatedData = [{ "id": 0, "schoolNameEn": "All", "schoolNameMr": "All" }, ...newData]
                    setData(newData);
                    setFilteredData(newData)
                    setLoaderOption(false);
                    //console.log(JSON.stringify(updatedData))
                }
            })
            .catch((error) => { console.log(error); })
    }

    const handleChange = (event) => {
        setFlag(event.target.checked);
        if (event.target.checked === true) {
            setFlag(1)
        }
        else {
            setFlag(0)
        }
    };

    const saveBtnClick = async (e) => {
        setLoaderOption(true);
        const saveObj = {
            "description": description,
            "flag": flag,
            "roleIds": roleId,
            "branchIds": option == 0 ? 0 : branchId
        }
        await axios.post(saveFormProcessingAPIURL, saveObj)
            .then((res) => {
                if (res.data === "SAVED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Sucessfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong.")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                console.log(err);
            })
    }

    const updateBtnClick = async (e) => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "description": description,
            "flag": flag,
            "roleIds": roleId,
            "branchIds": option == 0 ? 0 : branchId
        }
        console.log(JSON.stringify(updateObj))
        await axios.put(updateFormProcessingAPIURL, updateObj)
            .then((res) => {
                if (res.data === "UPDATED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Sucessfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong.")
                }
            }) 
            .catch((err) => {
                setLoaderOption(false);
                console.log(err);
            }) 
    }

    const ClearData = () => {
        setFlag(0);
        setDescription("");
        setRoleObj([]);
        setBranchObj([]);
        setBranchId([]);
        setRoleId([]);
        setOption(0);
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId="Form Processing" />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 20px #ABB2B9", marginTop: "16px", minHeight: "200px" }}>
                    <form onSubmit={onSubmit}>
                        <div className="form-data mt-1">

                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                            <div className="row">
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Description:</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        type='text'
                                        autoComplete='off'
                                        className=''
                                        size='small'
                                        id="outlined-basic"
                                        label="Description"
                                        variant="outlined"
                                        margin='dense'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        inputProps={{ maxLength: 120 }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Role :</label>
                                <div className='col-sm-4'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={roleData}
                                        multiple
                                        disableCloseOnSelect
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        onChange={(event, newValue) => {
                                            setRoleObj(newValue);
                                            setRoleId(newValue.map(e => e.id).join(','))
                                        }}
                                        value={roleObj}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.name}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Role" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                required={(roleObj.length == 0) ? true : false} />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                <div className="col-sm-4">
                                    <FormControl fullWidth sx={{ minWidth: 80 }} margin='dense'>
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Option</InputLabel>
                                        <Select
                                            size='small'

                                            PaperComponent={({ children }) => (
                                                <Paper style={{ backgroundColor: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            MenuProps={{ classes: { paper: classes.select } }}

                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={option}
                                            label="Option"
                                            onChange={(e) => {
                                                setOption(e.target.value)
                                                setBranchObj([]);
                                                setBranchId([]);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                        >
                                            <MenuItem value={0}>All</MenuItem>
                                            <MenuItem value={1}>Branch Wise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {(() => {
                                if (option == 1) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Branch :</label>
                                                <div className='col-sm-4'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={branchData}
                                                        multiple
                                                        disableCloseOnSelect
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        onChange={(event, newValue) => {
                                                            setBranchObj(newValue);
                                                            setBranchId(newValue.map(e => e.id).join(','))
                                                        }}
                                                        value={branchObj}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => option.schoolNameMr}
                                                        size="small"
                                                        renderInput={params => (
                                                            <TextField {...params} margin='dense' label="Select Branch" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                required={(branchObj.length == 0) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                        </>
                                    )
                                }
                            })()}

                            <div className="row my-2">
                                <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "10px" }}>Flag :</label>
                                <div className='col-sm-2'>
                                    <Switch
                                        checked={flag}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        margin='dense'
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-1">
                                    <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                    <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                    <button type="button" onClick={() => ClearData()} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>

                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="table-data mt-3">
                        <FormProcessingTable
                            TableHeading={TableHeading}
                            data={data}
                            filteredData={filteredData}
                            setFilteredData={setFilteredData}
                            setBranchId={setBranchId}
                            setBranchObj={setBranchObj}
                            setRoleId={setRoleId}
                            setRoleObj={setRoleObj}
                            setDescription={setDescription}
                            setFlag={setFlag}
                            setOption={setOption}
                            branchData={branchData}
                            roleData={roleData}
                            setSaveBtn={setSaveBtn}
                            setUpdateBtn={setUpdateBtn}
                            updateBtnStyle={updateBtnStyle}
                            displayNoneBtnStyle={displayNoneBtnStyle}
                            setSaveBtnDisabled={setSaveBtnDisabled}
                            setUpdateBtnDisabled={setUpdateBtnDisabled}
                            setId={setId}
                        />

                    </div>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }
}

export default FormProcessing
