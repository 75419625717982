import React from 'react';
import { Modal, Button } from "react-bootstrap";

const StudentDailyAttendaceModal = (props) => {
    //console.log("Chcked=======" + props.totalSelectedCheckboxes)
    return (
        <>

            <Modal show={props.modal}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to confirm the student daily attendance for class {props.fromClassName} on {props.date}?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setModal(false)}>Close</Button>
                    <Button variant="danger" onClick={() => props.confirmStudent()}>Confirm</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}


export default StudentDailyAttendaceModal
