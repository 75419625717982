import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlusCircle, faPrint } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { getFeeCollectionDetails, getStudentList, printFeeReceipt, deleteFeeReceipt, getMultiFeeCollectionListAPIURL, deleteMultiFeeCollectionAPIURL, printMuktipleFeeReceiptAPIURL } from '../Services/FeeReceiptAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import Loader from '../../CommonComponent/Loader';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const MultiFeeReceiptTable = () => {
    const authUser = useSelector((state) => state.user.value);
    // console.log(authUser)
    let { toastFlag } = useParams();
    const titleId = "Fee Receipt Table";
    const classes = useStyles();
    const navigate = useNavigate();

    let disableEditFlag = sessionStorage.getItem('FormProcessingFlag'); // Assuming it's a string
    disableEditFlag = JSON.parse(disableEditFlag); // Parse string to object

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [studentData, setStudentData] = useState([]);


    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    useEffect(() => {
        if (toastFlag == 1) {
            toast.success("Fee Receipt saved sucessfully.")
            navigate("/Home/f311/0")
        }
        else if (toastFlag == 2) {
            toast.success("Fee Receipt updated sucessfully.")
            navigate("/Home/f311/0")
        }
        getData();

    }, [authUser]);

    const getData = async () => {
        setLoaderOption(true);
        //console.log(`${getFeeCollectionDetails}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
        if (authUser.deptId != null || authUser.branchId != null || authUser.sessionYear != "") {
            await axios(`${getMultiFeeCollectionListAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
                .then(res => {
                    // console.log(JSON.stringify(res.data))
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false)
                }
                )
        }
        // if (authUser.deptId != null || authUser.branchId != null) {
        //     await axios(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        //         .then(res => {
        //             setStudentData(res.data);
        //             setLoaderOption(false)
        //         })
        // }

    }
    
    //langId=1&id=1&acYear=2021&sid=3&bid=1    
    const printFeeReceiptReport = async (id) => {
        setLoaderOption(true);
        console.log(`${printMuktipleFeeReceiptAPIURL}?langId=${authUser.branchMedium}&feeCollId=${id}`)
        await axios(`${printMuktipleFeeReceiptAPIURL}?langId=${authUser.branchMedium}&feeCollId=${id}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    toast.warn("Fee receipt not found.")
                }
                else if (res.data != null) {
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                    setLoaderOption(false)
                }
            })
            .catch((error) => {
                console.log("error name: " + error);
            });
    }

    const [filteredData, setFilteredData] = useState(data);

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    //item.studentId.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.transactionIdString.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.transactionDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.studentId + " - " + item.studentNameMr).toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.studentId + " - " + item.studentNameEn).toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.classNameMr + " " + item.divisionNameMr).toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.classNameEn + " " + item.divisionNameEn).toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.feeAmount.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const UpdateData = (item, EditFlag) => {
        navigate(`/Home/f311F/${item}/${EditFlag}`);
    }

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = (id) => {
        console.log("deleteddddddddd")
        axios.delete(`${deleteMultiFeeCollectionAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    toast.success("Deleted sucessfully");
                }
            });
    }

    const TableHeading = [
        { label: 'SrNo', key: 'id' },
        { label: `RecNo` + " | " + `RecDate`, key: `transactionIdString` },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Class-Division`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' },
        { label: `Amount`, key: 'feeAmount' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Print', value: 'print', isAction: true },
    ];

    const TableHeading2 = [
        { label: 'SrNo', key: 'id' },
        { label: `RecNo` + " | " + `RecDate`, key: `transactionIdString` },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Class-Division`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' },
        { label: `Amount`, key: 'feeAmount' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Print', value: 'print', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                        <div className=''>
                            <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/f311F/0/1")}><AddIcon fontSize="small" /> New Receipt</button>
                        </div>

                        <div className='row col-sm-4 mb-1' style={{ float: 'right' }}>
                            <div>
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    value={searchTerm}
                                    onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                />
                            </div>{" "}
                        </div>

                        <div className='table-responsive'>
                            <table className="table table-bordered">
                                <thead className="table-Default">
                                    <tr>
                                        {(() => {
                                            if (disableEditFlag) {
                                                return (
                                                    (disableEditFlag[2] === 1)
                                                        ?
                                                        TableHeading2.map((item, index) => {
                                                            return (
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                            )
                                                        })
                                                        :
                                                        TableHeading.map((item, index) => {
                                                            return (
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                            )
                                                        })
                                                )
                                            }
                                            else {
                                                return (
                                                    TableHeading2.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })
                                                )
                                            }
                                        })()}

                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            return (

                                                <tr key={index}>
                                                    <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.id}</td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{item.transactionIdString}<br />{moment(item.transactionDate).format("DD/MM/YYYY")}</td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{(authUser.branchMedium == 1) ? item.studentId + " - " + item.studentNameMr : item.studentId + " - " + item.studentNameEn}</td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{(authUser.branchMedium == 1) ? item.classNameMr + " " + item.divisionNameMr : item.classNameEn + " " + item.divisionNameEn}</td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{parseFloat(item.feeAmount).toFixed(2)}</td>

                                                    <td>
                                                        <Tooltip title="Edit">
                                                            <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                onClick={() => UpdateData(item.id, 2)} data-bs-toggle="tooltip" >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                    <td>
                                                        <Tooltip title="print">
                                                            <span className='btn btn-sm mx-1 p-1' style={{ backgroundColor: "#F1C40F", color: "white" }}
                                                                onClick={() => printFeeReceiptReport(item.id)} data-bs-toggle="tooltip" >
                                                                <FontAwesomeIcon icon={faPrint} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                    <td hidden={disableEditFlag ? (disableEditFlag[2] === 1 ? false : true) : false}>
                                                        <Tooltip title="Delete">
                                                            <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                onClick={() => setDeleteId(item.id)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                </tr>

                                            )
                                        })}
                                </tbody>
                                <tfoot style={{ padding: "0px" }}>
                                    <tr>
                                        <TablePagination
                                            style={{ padding: "0px" }}
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                            onPageChange={onPageChange}
                                            labelRowsPerPage="Rows Per Page :"
                                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            }
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default MultiFeeReceiptTable
