import React, { useState, useEffect } from 'react';
import TitleLabel from '../../CommonComponent/TitleLabel';
import Loader from '../../CommonComponent/Loader';
import { useSelector } from 'react-redux';
import { Checkbox, TextField } from '@mui/material';
import { getStudRegUpdateDataAPIURL, updateStudRegAPIURL } from '../Services/StudRegUpdateAPIURL';
import axios from 'axios';
import { GetStudentLcDataByRegNo } from '../../CommonServices/LeavingCertificateAPIURL';
import { getStudentListForEdit } from '../../FeeManagement/Services/FeeReceiptAPIURL';
import { toast } from 'react-toastify';
import StudRegUpdateModal from './StudRegUpdateModal';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { Square } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const StudRegUpdate = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Reg. Update";

    const [loaderOption, setLoaderOption] = useState(false);
    const [wrongRegNo, setWrongRegNo] = useState('');
    const [studentName, setStudentName] = useState('');
    const [classDivName, setClassDivName] = useState('');
    const [correctRegNo, setCorrectRegNo] = useState('');
    const [data, setData] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState([]); // Track the state of checkboxes
    const [modal, setModal] = useState(false);
    const [updatechkBox, setUpdateChkBox] = useState(false);


    const handlePreventDefault = (event) => {
        event.preventDefault();
    };

    const getStudentData = async (oldRegNo) => {
        setLoaderOption(true);
        setUpdateChkBox(false);
        setData([]);
        let notFoundCount = 0; // To track "NOTFOUND" responses

        try {
            const response1 = await axios.get(`${GetStudentLcDataByRegNo}?regNo=${oldRegNo}&sid=${authUser.deptId}&bid=${authUser.branchId}`);
            if (response1.data === "NOTFOUND") {
                notFoundCount++;
            } else {
                setClassDivName(authUser.branchMedium === 1 ? `${response1.data.currentClassMr} ${response1.data.currentDivisionMr}` : `${response1.data.currentClassEn} ${response1.data.currentDivisionEn}`);
                setStudentName(authUser.branchMedium === 1 ? `${response1.data.firstNameMr} ${response1.data.middleNameMr} ${response1.data.lastNameMr}` : `${response1.data.firstNameEn} ${response1.data.middleNameEn} ${response1.data.lastNameEn}`);
            }

            const response2 = await axios.get(`${getStudRegUpdateDataAPIURL}?oldRegNo=${oldRegNo}&sid=${authUser.deptId}&bid=${authUser.branchId}`);
            if (response2.data === "NOTFOUND") {
                notFoundCount++;
            } else {
                setData(response2.data);
                // Initialize checkbox states
                setCheckboxStates(response2.data.map(() => false));
            }

            if (notFoundCount === 2) {
                toast.warn("Student for this reg no is not found or This reg no is already allocated.");
            }
        } catch (error) {
            console.error('Error fetching student data:', error);
            toast.error('An error occurred while fetching data.');
        } finally {
            setLoaderOption(false);
        }
    }

    // const getStudentDetails = async (newRegNo) => {
    //     setLoaderOption(true);
    //     if (wrongRegNo !== '') {
    //         if (studentName != '' || classDivName != '') {
    //             await axios.get(`${getStudRegUpdateDataAPIURL}?oldRegNo=${wrongRegNo}&newRegNo=${newRegNo}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
    //                 .then(res => {
    //                     if (res.data == "ALREADYEXISTS") {
    //                         setLoaderOption(false);
    //                         toast.warn("This reg no is already allocated.")
    //                     }
    //                     else {
    //                         setData(res.data);
    //                         setLoaderOption(false);
    //                         // Initialize checkbox states
    //                         setCheckboxStates(res.data.map(() => false));
    //                     }

    //                 })
    //         }
    //         else {
    //             setLoaderOption(false);
    //             toast.warn("Student for this reg no is not found.")
    //         }
    //     }


    // }

    const updateStudRegData = async (e) => {
        e.preventDefault();
        setModal(true);
    }

    const updateStudRegConfirmBox = async () => {
        setModal(false);
        setLoaderOption(true);

        if (studentName != '' || classDivName != '') {
            await axios.put(`${updateStudRegAPIURL}?oldRegNo=${wrongRegNo}&newRegNo=${correctRegNo}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {

                    if (res.data == "UPDATED") {
                        // Update all checkboxes to checked
                        setCheckboxStates(checkboxStates.map(() => true));
                        setUpdateChkBox(true);

                        setLoaderOption(false);

                        toast.success("Updated Successfully.")

                    }
                    else if (res.data == "ALREADYEXISTS") {
                        setLoaderOption(false);
                        toast.warn("This reg no is already allocated.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Update failed.")
                    }
                }).catch(err => {
                    console.log("Stud Reg update err" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.warn("Student for this reg no is not found.")
        }
    }

    useEffect(() => {
        setCheckboxStates(data.map(() => false));
    }, [data]);

    return (
        <>
            {
                (loaderOption === true)
                    ? <Loader />
                    : <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={updateStudRegData}>
                                <div className="row">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Wrong Reg No :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required
                                            fullWidth
                                            margin='dense'
                                            onChange={e => {
                                                setWrongRegNo(e.target.value);
                                                setStudentName('');
                                                setClassDivName('');
                                                setCorrectRegNo('');
                                                setData([]);
                                            }}
                                            //onKeyPress={(e) => (e.key === "Enter") ? wrongRegNo : ""}
                                            type="number"
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Wrong Reg No"
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            value={wrongRegNo}
                                            onBlur={(e) => getStudentData(e.target.value)}
                                        />
                                    </div>

                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            margin='dense'
                                            onChange={e => setStudentName(e.target.value)}
                                            //onKeyPress={(e) => (e.key === "Enter") ? studentName : ""}
                                            type="text"
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Student Name"
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                            value={studentName}
                                        />
                                    </div>

                                    <div className='col-sm-2'>
                                        <TextField
                                            required
                                            fullWidth
                                            margin='dense'
                                            onChange={e => setClassDivName(e.target.value)}
                                            //onKeyPress={(e) => (e.key === "Enter") ? classDivName : ""}
                                            type="text"
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Class-Div Name"
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                            value={classDivName}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Correct Reg No :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required
                                            fullWidth
                                            margin='dense'
                                            onChange={e => {
                                                setCorrectRegNo(e.target.value);
                                                //setData([]);
                                            }}
                                            //onKeyPress={(e) => (e.key === "Enter") ? correctRegNo : ""}
                                            type="number"
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Correct Reg No"
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            value={correctRegNo}
                                        // onBlur={(e) => getStudentDetails(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-sm-4 mt-3">
                                        <button type='submit' className='btn btn-sm btn-primary'>Update</button>
                                    </div>
                                </div>
                            </form>

                            <div className="row mt-5">
                                <div className="col-sm-8 offset-sm-2">
                                    <div className='table-responsive'>
                                        <table className="table table-bordered">
                                            <tbody style={{ border: "2px solid #858585" }}>
                                                {
                                                    data.length != 0
                                                        ?
                                                        data.map((item, index) => (
                                                            <tr key={index}>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px", textAlign: 'center' }}>
                                                                    {
                                                                        (updatechkBox == true)
                                                                            ?
                                                                            <CheckCircleIcon
                                                                                sx={{
                                                                                    fontSize: 22,
                                                                                    color: '#1F8700',
                                                                                    //marginLeft: 1 // Optional: add some spacing between the checkbox and the icon
                                                                                }}
                                                                            />
                                                                            :
                                                                            <RadioButtonUncheckedIcon
                                                                                sx={{
                                                                                    fontSize: 22,
                                                                                    //color: '#fff',
                                                                                    //marginLeft: 1 // Optional: add some spacing between the checkbox and the icon
                                                                                }}
                                                                            />
                                                                    }

                                                                    {/* <Checkbox
                                                                    checked={checkboxStates[index]}
                                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                                                                    onClick={handlePreventDefault}
                                                                    onChange={handlePreventDefault}
                                                                /> */}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        :
                                                        ""
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
            <StudRegUpdateModal updateStudRegConfirmBox={updateStudRegConfirmBox} modal={modal} setModal={setModal} />
        </>
    )
}

export default StudRegUpdate;
