import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { getClassDivisionURL } from '../Services/SubjectAllocationAPIURL';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { generateStudentWiseExternalExamReportAPIURL } from '../Services/ExternalExamAllocationAPIURL';
import { getExternalExamAPIURL } from '../../Masters/Services/ExternalExamAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const optionData = [
    {
        id: 1,
        optionName: "All"
    },
    {
        id: 2,
        optionName: "Class-Division Wise"
    },
    {
        id: 3,
        optionName: "Subject Wise"
    }
]

const ExternalExamAllocationReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "External Exam Allocation Report";

    const [examData, setExamData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [examObj, setExamObj] = useState(null);
    const [classObj, setClassObj] = useState(null);
    const [optionId, setOptionId] = useState(1);
    const [classId, setClassId] = useState(null);
    const [divisionId, setDivisionId] = useState(null);
    const [view, setView] = useState(1);

    const [loaderOption, setLoaderOption] = useState(false);

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                })
        }

        await axios(`${getExternalExamAPIURL}`)
            .then(res => {
                setExamData(res.data);
            })
    }


    const getExternalExamAllocationReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        //console.log(`${generateSubjectAllocationReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${generateStudentWiseExternalExamReportAPIURL}?langId=${authUser.branchMedium}&extExamId=${examObj.id}&classId=${(optionId == 1) ? 0 : classId}&divId=${(optionId == 1) ? 0 : divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&option=${optionId}&reportType=${view}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("Subject Allocation failed: " + error)
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={getExternalExamAllocationReport}>

                                <div className="row">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>View :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>View</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={view}
                                                label="View"
                                                onChange={(e) => {
                                                    setView(e.target.value);
                                                    setOptionId(1);
                                                    setClassObj(null);
                                                    setExamObj(null);
                                                }}
                                            >
                                                <MenuItem value={1}>PDF</MenuItem>
                                                <MenuItem value={2}>Excel</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className='row my-3'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Report Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth >
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={optionId}
                                                margin='dense'
                                                label="Select Report Option"
                                                onChange={(e) => {
                                                    setOptionId(e.target.value);
                                                    setClassObj(null);
                                                    setExamObj(null);
                                                }}
                                            >
                                                <MenuItem value={1}>All</MenuItem>
                                                <MenuItem value={2}>Class-division Wise</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>

                                {(() => {
                                    if (optionId == 2) {
                                        return (
                                            <div className='row'>
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                                <div className='col-sm-3'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={classData}
                                                        style={{ fontSize: '14px' }}
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        onChange={(event, item) => {
                                                            setClassObj(item);
                                                            setClassId(item.classId);
                                                            setDivisionId(item.divisionId);
                                                            setExamObj(null);
                                                        }}
                                                        value={classObj}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                        size="small"
                                                        renderInput={params => (
                                                            <TextField {...params} margin='dense' label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                required={(optionId == 2) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}

                                <div className='row my-3'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={examData}
                                            style={{ fontSize: '14px' }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            onChange={(event, item) => {
                                                setExamObj(item);
                                            }}
                                            value={examObj}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Exam" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required />
                                            )}
                                        />
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-2">
                                        <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}



export default ExternalExamAllocationReport
